import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 25px;
  padding: 0 1px;
  /*overflow-y: scroll;
  overflow-x: hidden;
  height: 65dvh;*/
  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #343536;
  }
`;

export const BoxDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #343536;
  }
`;

export const BoxButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-top: 15px;
  gap: 20px;
`;

export const BtnFixed = styled.div`
  //position: fixed;
  //bottom: 15px;
  //width: 93%;

  display: flex;
  flex-direction: row;
  gap: 25px;
  padding-bottom: 15px;
`;
export const AriaBox = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px 10px;
  gap: 10px;

  label {
    padding: 5px 8px;
    background-color: #f7f8fa;
    margin-left: 10px;
  }
`;
