import API from "shared/services/Api";
import {
  ISchedule,
  IScheduleInit,
  IScheduleInitResponse,
  IScheduleResponse,
} from "shared/types/Schedule";

export const getSchedule = async (
  data: ISchedule
): Promise<IScheduleResponse> =>
  API.get(`oc/schedules?id=${data.id}&date=${data.date}`);

export const scheduleInit = async (
  data: IScheduleInit
): Promise<IScheduleInitResponse> => API.post("oc/service/init", data);
