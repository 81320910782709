export interface Question {
  questionText: string;
  questionIdentifier: string;
  children?: Question[];
  answerValidate?: any;
  stepJump?: boolean;
}

export const questions: Question[] = [
  { questionText: "Faz uso de medicamento?", questionIdentifier: "OCQ13" },
  { questionText: "Anomalia de nascença?", questionIdentifier: "OCQ14" },
  {
    questionText: "Cirurgia anterior?",
    questionIdentifier: "OCQ15",
  },

  {
    questionText: "A recuperação ocorreu como esperado?",
    questionIdentifier: "OCQ25",
    answerValidate: [["Cirurgia anterior?", "sim"]],
  },

  {
    questionText: "Teve Hemorragia?",
    questionIdentifier: "OCQ26",
    answerValidate: [["A recuperação ocorreu como esperado?", "any"]],
  },

  {
    questionText: "Já foi anestesiado?",
    questionIdentifier: "OCQ16",
    answerValidate: [
      ["Teve Hemorragia?", "any"],
      ["Cirurgia anterior?", "não"],
    ],
  },
  { questionText: "Distúrbios respiratórios?", questionIdentifier: "OCQ17" },
  { questionText: "Doenças sanguíneas?", questionIdentifier: "OCQ18" },
  { questionText: "Doença óssea?", questionIdentifier: "OCQ19" },
  {
    questionText: "Problemas endócrinos?",
    questionIdentifier: "OCQ20",
  },

  { questionText: "Doenças cardíacas?", questionIdentifier: "OCQ21" },
  { questionText: "Possui alguma alergia?", questionIdentifier: "OCQ22" },
  {
    questionText: "Traumatismo de face ou cabeça?",
    questionIdentifier: "OCQ23",
  },
  {
    questionText: "Fraturas ósseas?",
    questionIdentifier: "OCQ24",
  },

  {
    questionText: "A recuperação aconteceu como esperado?",
    questionIdentifier: "OCQ27",
    answerValidate: [["Fraturas ósseas?", "sim"]],
  },
];
