import React from "react";

import * as Styled from "./styles";

import { ReactComponent as ClockIcon } from "shared/assets/svg/clock.svg";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { DEFAULT_AVATAR } from "shared/config/properties";
import { useNavigate } from "react-router-dom";
import { ROUTER } from "shared/constants/router";

import { ReactComponent as MenuIcon } from "shared/assets/svg/menu.svg";
import { Schedule, TimeSlot } from "shared/types/Schedule";
import { postDiagnosis } from "requests/Diagnosis";
import { useAuth } from "shared/providers/auth";
//import { Odontograma } from "components/Odontograma";
import { scheduleInit } from "requests/Schedule";
import toast from "react-hot-toast";
//import { IOdont } from "shared/types/SendSchedule";
import { Documentation } from "components/Documentation";
import { ClinicalRecordUser } from "components/ClinicalRecord";
import { OdontogramaUser } from "components/OdontogramaUser";
import { getAlerts } from "requests/Alert";

interface IProps {
  data: TimeSlot;
}

const CardList: React.FC<IProps> = ({ data }) => {
  const navigate = useNavigate();
  const { user, setAlerts, setModalOpen } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openOdonto, setOpenOdonto] = React.useState<boolean>(false);

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [typeBraceId, setTypeBraceId] = React.useState<number>(0);
  const [pacientId, setPacientId] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [message, setMessage] = React.useState<string>("");
  const [pos, setPos] = React.useState<boolean>(false);

  const [openCloseDocumentation, setOpenCloseDocumentation] =
    React.useState<boolean>(false);

  const [openCloseClinicalRecord, setOpenCloseClinicalRecord] =
    React.useState<boolean>(false);

  const closeModal = () => {
    setOpenModal(false);
    window.location.reload();
    setMessage("");
    setPos(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setTypeBraceId(Number(event.target.value));
  };

  const init = async (v: Schedule, readInit: string) => {
    if (readInit === "C" || readInit === "T") {
      if (v.type === 0 || v.type === null) {
        if (v.classification !== 0 && v.classification !== 1) {
          setPacientId(v.pac_id);
          setOpenModal(true);
          return false;
        }
      }

      const { data: dataAlert, status: statusAlert } = await getAlerts(
        user.data.id,
        v.budget
      );
      if ([200, 201].includes(statusAlert)) {
        if (dataAlert.data.length > 0) {
          setAlerts(dataAlert.data);
          setModalOpen(true);
        }
      }

      if (v.classification === 0) {
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          const obj = JSON.stringify(v);
          localStorage.setItem("personAvaliation", obj);
          navigate(ROUTER.AVALIATION);
        } else {
          toast.error(data.message);
        }
      }

      if (v.classification === 1) {
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          const obj = JSON.stringify(v);
          localStorage.setItem("personFirst", obj);
          navigate(ROUTER.FIRST_CONSULT);
        } else {
          toast.error(data.message);
        }
      }

      if (v.classification === 3) {
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          const obj = JSON.stringify(v);
          localStorage.setItem("personMaintenance", obj);
          navigate(ROUTER.MAINTENANCE);
        } else {
          toast.error(data.message);
        }
      }

      /*
    
      if (v.proc_name === "1ª Consulta") {
        if (v.type) {
          localStorage.setItem("typeBraceFirstConsult", v.type);
        }
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          
          const obj = JSON.stringify(v);
          localStorage.setItem("personFirst", obj);
          navigate(ROUTER.FIRST_CONSULT);
        } else {
          toast.error(data.message);
        }
      }

      if (v.proc_name === "2ª Consulta") {
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          const obj = JSON.stringify(v);
          localStorage.setItem("personSecond", obj);
          navigate(ROUTER.SECOND_CONSULT);
        } else {
          toast.error(data.message);
        }
      }

      if (v.proc_name === "Avaliação") {
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          const obj = JSON.stringify(v);
          localStorage.setItem("personAvaliation", obj);
          navigate(ROUTER.AVALIATION);
        } else {
          toast.error(data.message);
        }
      }

      if (v.proc_name === "Mini Implante") {
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          const obj = JSON.stringify(v);
          localStorage.setItem("personMiniImplant", obj);
          navigate(ROUTER.MINI_IMPLANT);
        } else {
          toast.error(data.message);
        }
      }

      if (v.proc_name === "Remoção de Aparatologia Ortodôntica") {
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          const obj = JSON.stringify(v);
          localStorage.setItem("personRemoteAparat", obj);
          navigate(ROUTER.REMOTE_APARAT);
        } else {
          toast.error(data.message);
        }
      }

      if (v.proc_name === "Controle de Contenção") {
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          const obj = JSON.stringify(v);
          localStorage.setItem("personContainControl", obj);
          navigate(ROUTER.CONTAIN_CONTROL);
        } else {
          toast.error(data.message);
        }
      }

      if (v.proc_name === "Controle de erupção") {
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          const obj = JSON.stringify(v);
          localStorage.setItem("personEruptionControl", obj);
          navigate(ROUTER.ERUPTION_CONTROL);
        } else {
          toast.error(data.message);
        }
      }

      if (v.proc_name === "Elasticos Intermaxilares") {
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          const obj = JSON.stringify(v);
          localStorage.setItem("personIntermaxillaryElastic", obj);
          navigate(ROUTER.INTERMAXILLARY_ELASTIC);
        } else {
          toast.error(data.message);
        }
      }

      if (v.proc_name === "Colagem de botão") {
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          const obj = JSON.stringify(v);
          localStorage.setItem("personCollageButton", obj);
          navigate(ROUTER.COLLAGE_BUTTON);
        } else {
          toast.error(data.message);
        }
      }

      if (v.proc_name === "Recolagem de Contenção") {
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          const obj = JSON.stringify(v);
          localStorage.setItem("personContainRebonding", obj);
          navigate(ROUTER.CONTAIN_REBONDING);
        } else {
          toast.error(data.message);
        }
      }

      if (v.proc_name === "Levante de mordida") {
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          const obj = JSON.stringify(v);
          localStorage.setItem("personBiteLift", obj);
          navigate(ROUTER.BITE_LIFT);
        } else {
          toast.error(data.message);
        }
      }

      if (v.proc_name === "Alta") {
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          const obj = JSON.stringify(v);
          localStorage.setItem("personRelease", obj);
          navigate(ROUTER.RELEASE);
        } else {
          toast.error(data.message);
        }
      }

      if (v.proc_name === "Manutenção") {
        const { status, data } = await scheduleInit({
          id: user.data.id,
          idPac: v.pac_id,
          idSchedule: v.id,
        });

        if ([200, 201].includes(status)) {
          const obj = JSON.stringify(v);
          localStorage.setItem("personMaintenance", obj);
          navigate(ROUTER.MAINTENANCE);
        } else {
          toast.error(data.message);
        }
      }*/
    }
  };

  const registerDiagnosis = async () => {
    setLoading(true);

    try {
      const { data: dataDiagnosis, status } = await postDiagnosis({
        id: user.data.id,
        idPac: pacientId,
        type: typeBraceId,
      });

      if ([200, 201].includes(status)) {
        setMessage(dataDiagnosis.message);
        setPos(true);
        setLoading(false);
      } else {
        setMessage(dataDiagnosis.message);
        setPos(true);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <Styled.Content>
        <Styled.HourBox>
          <Styled.TimeBox>
            <ClockIcon width="25px" height="25px" />{" "}
            <Styled.HourStyle>{data.time}</Styled.HourStyle>
          </Styled.TimeBox>
          {data.data.length > 0 ? (
            data.data.map((item, index) => (
              <Styled.BoxSituation situation={item.st_schedule} key={index}>
                <p>
                  {item.inService === 1 && item.classification === 0
                    ? "Em atendimento"
                    : item.st_schedule === "C"
                    ? "Chegou"
                    : item.st_schedule === "T"
                    ? "Chegou atrasado"
                    : item.st_schedule === "A"
                    ? "Agendado"
                    : item.st_schedule === "E"
                    ? "Encaixe"
                    : item.st_schedule === "K"
                    ? "Atendido"
                    : item.st_schedule === "M"
                    ? "Remarcado (Clínica)"
                    : item.st_schedule === "N"
                    ? "Não Compareceu"
                    : item.st_schedule === "Y" && "Atendido/Atrasado"}
                </p>
              </Styled.BoxSituation>
            ))
          ) : (
            <Styled.BoxSituation situation={""}>
              <p>Horário Vago</p>
            </Styled.BoxSituation>
          )}
        </Styled.HourBox>

        {data &&
          data.data &&
          data.data.map((item, index) => (
            <React.Fragment key={index}>
              <Styled.BoxInfo>
                {item.pac_name && (
                  <>
                    <Styled.Picture>
                      <Avatar
                        src={item.pac_avatar ? item.pac_avatar : DEFAULT_AVATAR}
                        alt={item.pac_name}
                        sx={{ width: "55px", height: "55px" }}
                      />
                    </Styled.Picture>
                    <Styled.Infos>
                      <Styled.Id>ID {item.pac_id_fant}</Styled.Id>
                      <Styled.Name>{item.pac_name}</Styled.Name>
                      <Styled.TypeConsult>{item.proc_name}</Styled.TypeConsult>
                    </Styled.Infos>
                  </>
                )}
              </Styled.BoxInfo>
              {item.pac_name && item.classification > 0 ? (
                <button
                  onClick={handleClick}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                >
                  <MenuIcon />
                </button>
              ) : (
                <div style={{ height: "81px", width: "78.82px" }}></div>
              )}

              {item.st_schedule !== "C" && item.st_schedule !== "T" && (
                <Styled.BoxBtnFake>
                  <Button>INICIAR</Button>
                </Styled.BoxBtnFake>
              )}

              {item.st_schedule === "C" &&
                item.classification === 0 &&
                item.inService !== 0 && (
                  <Styled.BoxBtnFake>
                    <Button>INICIAR</Button>
                  </Styled.BoxBtnFake>
                )}

              {item.st_schedule === "T" &&
                item.classification === 0 &&
                item.inService !== 0 && (
                  <Styled.BoxBtnFake>
                    <Button>INICIAR</Button>
                  </Styled.BoxBtnFake>
                )}

              {item.st_schedule === "C" && item.classification !== 0 && (
                <Styled.BoxBtn>
                  <Button
                    onClick={() => init(item, item.st_schedule)}
                    variant={
                      item.st_schedule === "C" ? "contained" : "outlined"
                    }
                  >
                    INICIAR
                  </Button>
                </Styled.BoxBtn>
              )}
              {item.st_schedule === "T" && item.classification !== 0 && (
                <Styled.BoxBtn>
                  <Button
                    onClick={() => init(item, item.st_schedule)}
                    variant={
                      item.st_schedule === "T" ? "contained" : "outlined"
                    }
                  >
                    INICIAR
                  </Button>
                </Styled.BoxBtn>
              )}

              {item.st_schedule === "T" &&
                item.classification === 0 &&
                item.inService !== 1 && (
                  <Styled.BoxBtn>
                    <Button
                      onClick={() => init(item, item.st_schedule)}
                      variant={
                        item.st_schedule === "T" ? "contained" : "outlined"
                      }
                    >
                      INICIAR
                    </Button>
                  </Styled.BoxBtn>
                )}

              {item.st_schedule === "C" &&
                item.classification === 0 &&
                item.inService !== 1 && (
                  <Styled.BoxBtn>
                    <Button
                      onClick={() => init(item, item.st_schedule)}
                      variant={
                        item.st_schedule === "C" ? "contained" : "outlined"
                      }
                    >
                      INICIAR
                    </Button>
                  </Styled.BoxBtn>
                )}
            </React.Fragment>
          ))}
      </Styled.Content>
      {data.data
        ?.filter((key) => key.pac_name.length > 0)
        .map((item) => (
          <Menu
            key={item.pac_id}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => setOpenCloseDocumentation(true)}
              sx={{ fontSize: "17px", padding: "10px" }}
            >
              Documentação
            </MenuItem>

            <MenuItem
              sx={{ fontSize: "17px", padding: "10px" }}
              onClick={() => setOpenOdonto(true)}
            >
              Odontograma
            </MenuItem>
            <MenuItem
              onClick={() => setOpenCloseClinicalRecord(true)}
              sx={{ fontSize: "17px", padding: "10px" }}
            >
              Ficha Clínica
            </MenuItem>

            <Modal
              open={openCloseDocumentation}
              onClose={() => setOpenCloseDocumentation(false)}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Documentation
                  onClick={() => setOpenCloseDocumentation(false)}
                  idPacientParam={item.pac_id}
                />
              </Box>
            </Modal>

            <Modal
              open={openCloseClinicalRecord}
              onClose={() => setOpenCloseClinicalRecord(false)}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                }}
              >
                <ClinicalRecordUser
                  onClick={() => setOpenCloseClinicalRecord(false)}
                  idPacientParam={item.pac_id}
                />
              </Box>
            </Modal>

            <Modal open={openOdonto} onClose={() => setOpenOdonto(false)}>
              <OdontogramaUser
                idPacientParam={item.pac_id}
                onClick={() => setOpenOdonto(false)}
              />
            </Modal>
          </Menu>
        ))}

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Styled.BoxModal>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {pos ? (
                <>
                  <Typography>{message}</Typography>
                  <Button onClick={closeModal}>Fechar</Button>
                </>
              ) : (
                <>
                  <Typography>
                    Escolha um tipo de aparelho para continuar
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Tipo de aparelho
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={String(typeBraceId)}
                      label="Tipo de aparelho"
                      onChange={handleChange}
                    >
                      <MenuItem value={0}>Sem aparelho</MenuItem>
                      <MenuItem value={1}>Aparelho Fixo</MenuItem>
                      <MenuItem value={2}>Aparelho Móvel</MenuItem>
                    </Select>
                  </FormControl>
                  <Styled.BoxButtons>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancelar
                    </Button>
                    <Button onClick={registerDiagnosis}>Enviar</Button>
                  </Styled.BoxButtons>
                </>
              )}
            </>
          )}
        </Styled.BoxModal>
      </Modal>
    </>
  );
};

export { CardList };
