import React from "react";

import { Button, Container } from "@mui/material";
import { ReturnButton } from "components/ReturnButton";

import * as Styled from "./styles";
import { useAuth } from "shared/providers/auth";

const Exit: React.FC = () => {
  const { deslogged, user } = useAuth();
  return (
    <Container maxWidth="xl">
      <ReturnButton title="Deseja sair da sua conta?" />
      <Styled.BoxBtn>
        <Button onClick={() => deslogged(user.data.id)}>SAIR</Button>
        <Button variant="outlined" onClick={() => window.history.back()}>
          Cancelar
        </Button>
      </Styled.BoxBtn>
    </Container>
  );
};

export { Exit };
