import React from "react";
import * as Styled from "./styles";
import {
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  TextField,
  Select,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  Modal,
  Typography,
} from "@mui/material";
import { useFirstConsult } from "shared/providers/FirstConsult";
import { materialOptions, numbers } from "shared/mock";
import { Odontograma } from "components/Odontograma";
import { useAuth } from "shared/providers/auth";
import toast from "react-hot-toast";

const PlanningFixed: React.FC = () => {
  const {
    setStep,
    setValueEnc,
    //valueOthers,
    setValueOthers,
    othersReferrals,
    setOthersReferrals,
    othersProcedures,
    setOthersProcedures,
    aparate,
    setAparate,
    wire,
    setWire,
    keepThread,
    setKeepThread,
    ciment,
    setCiment,
    glue,
    setGlue,
    complement,
    setComplement,

    aparateBrace,
    setAparateBrace,
    changeWire,
    setChangeWire,
    keep,
    setKeep,
    cimentBand,
    setCimentBand,
    glueTube,
    setGlueTube,
    comp,
    setComp,

    setMaterialCola,
    setNumberCola,
    setOthersCola,
    setMaterialChange,
    setNumberChange,
    setOthersChange,
    setAtivation,
    odontogramaCiment,
    setOdontogramaCiment,
    odontogramaCollage,
    setOdontogramaCollage,
    //odontogramaComplement,
    //setOdontogramaComplement,
    materialCola,
    numberCola,
    materialChange,
    numberChange,
    odontogramaPlanningAparat,
    setOdontogramaPlanningAparat,
    odontogramaChangeWire,
    setOdontogramaChangeWire,
    valueEnc,
    valueOthers,
    ativation,
    othersCola,
    othersChange,

    materialColaInf,
    setMaterialColaInf,
    numberColaInf,
    setNumberColaInf,
    othersColaInf,
    setOthersColaInf,

    materialChangeInf,
    setMaterialChangeInf,
    numberChangeInf,
    setNumberChangeInf,
    othersChangeInf,
    setOthersChangeInf,
  } = useFirstConsult();

  const { complementary, proceduresData, referralsData } = useAuth();

  const [modalCiment, setModalCiment] = React.useState<boolean>(false);

  const [modalCollage, setModalCollage] = React.useState<boolean>(false);

  //const [modalComplement, setModalComplement] = React.useState<boolean>(false);

  const [modalPlanningAparat, setModalPlanningAparat] =
    React.useState<boolean>(false);

  const [modalChangeWire, setModalChangeWire] = React.useState<boolean>(false);

  const next = () => {
    if (
      othersReferrals &&
      !aparate &&
      !wire &&
      !keepThread &&
      !ciment &&
      !glue &&
      !complement &&
      !othersProcedures
    ) {
      toast.error("Selecione mais algum planejamento e tente novamente.");
      return false;
    }

    if (aparate && aparateBrace.length < 1) {
      toast.error("Selecione o tipo de Colagem da Aparatologia Ortodontica");
      return false;
    }

    if (aparateBrace.length > 0) {
      if (aparateBrace.includes("SUP")) {
        if (!materialCola) {
          toast.error(
            "Selecione o material de Colagem da Aparatologia Ortodontica Superior"
          );
          return false;
        }

        if (!numberCola) {
          toast.error(
            "Selecione o número de Colagem da Aparatologia Ortodontica Superior"
          );
          return false;
        }
      }

      if (aparateBrace.includes("INF")) {
        if (!materialColaInf) {
          toast.error(
            "Selecione o material de Colagem da Aparatologia Ortodontica Inferior"
          );
          return false;
        }

        if (!numberColaInf) {
          toast.error(
            "Selecione o número de Colagem da Aparatologia Ortodontica Inferior"
          );
          return false;
        }
      }
    }

    if (wire && changeWire.length < 1) {
      toast.error("Selecione o tipo de Trocar Fio");
      return false;
    }

    if (changeWire.length > 0) {
      if (changeWire.includes("SUP")) {
        if (!materialChange) {
          toast.error("Selecione o material de Trocar Fio Superior");
          return false;
        }

        if (!numberChange) {
          toast.error("Selecione o número de Trocar Fio Superior");
          return false;
        }
      }

      if (changeWire.includes("INF")) {
        if (!materialChangeInf) {
          toast.error("Selecione o material de Trocar Fio Superior");
          return false;
        }

        if (!numberChangeInf) {
          toast.error("Selecione o número de Trocar Fio Superior");
          return false;
        }
      }
    }

    if (keepThread && keep.length < 1) {
      toast.error("Selecione o tipo de Manter Fio");
      return false;
    }

    if (keep.length > 0) {
      if (ativation === "") {
        toast.error("Selecione se é com ou sem ativção");
        return false;
      }
    }

    if (ciment && cimentBand.length < 1) {
      toast.error("Selecione o tipo de Cimentação de Banda");
      return false;
    }

    if (glue && glueTube.length < 1) {
      toast.error("Selecione o tipo de Colagem de Tubo");
      return false;
    }

    if (complement && comp.length < 1) {
      toast.error("Selecione pelo menos um Aparelho Complementar");
      return false;
    }

    if (othersProcedures && !valueOthers) {
      toast.error("Selecione pelo menos um procedimento");
      return false;
    }

    if (
      !aparate &&
      !wire &&
      !keepThread &&
      !ciment &&
      !glue &&
      !complement &&
      !othersProcedures &&
      !othersReferrals
    ) {
      toast.error("Selecione pelo menos um procedimento.");
      return false;
    } else {
      setStep(5);
    }
  };

  return (
    <Styled.Box>
      <Styled.BoxIndication>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={aparate}
                onClick={() => {
                  setAparate(!aparate);
                  setAparateBrace([]); // Reseta o estado ao desmarcar
                }}
              />
            }
            label="Colagem da Aparatologia Ortodôntica"
          />
        </FormGroup>
        {aparate && (
          <>
            <Styled.BoxButtons>
              <Button
                variant={
                  aparateBrace.includes("SUP") ? "contained" : "outlined"
                }
                onClick={() => {
                  if (aparateBrace.includes("SUP")) {
                    setAparateBrace(
                      aparateBrace.filter((type) => type !== "SUP")
                    );
                  } else {
                    setAparateBrace([...aparateBrace, "SUP"]);
                  }
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: aparateBrace.includes("SUP")
                      ? "#007DED"
                      : "white",
                    color: aparateBrace.includes("SUP") ? "white" : "#007DED",
                    borderColor: aparateBrace.includes("SUP")
                      ? "#007DED"
                      : "#EEEFF1",
                  },
                }}
              >
                Superior
              </Button>
              <Button
                variant={
                  aparateBrace.includes("INF") ? "contained" : "outlined"
                }
                onClick={() => {
                  if (aparateBrace.includes("INF")) {
                    setAparateBrace(
                      aparateBrace.filter((type) => type !== "INF")
                    );
                  } else {
                    setAparateBrace([...aparateBrace, "INF"]);
                  }
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: aparateBrace.includes("INF")
                      ? "#007DED"
                      : "white",
                    color: aparateBrace.includes("INF") ? "white" : "#007DED",
                    borderColor: aparateBrace.includes("INF")
                      ? "#007DED"
                      : "#EEEFF1",
                  },
                }}
              >
                Inferior
              </Button>
            </Styled.BoxButtons>

            {aparateBrace.length > 0 && (
              <Box display="flex" flexDirection="column" mt={2}>
                {aparateBrace.includes("SUP") && (
                  <>
                    <Typography textAlign="left">Superior</Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      mt={2}
                      mb={2}
                      width="100%"
                    >
                      <FormControl fullWidth>
                        <InputLabel
                          id="material-select-label"
                          sx={{
                            backgroundColor: "white",
                            padding: "2px",
                          }}
                        >
                          Material
                        </InputLabel>
                        <Select
                          displayEmpty
                          variant="outlined"
                          sx={{ width: "90%" }}
                          style={{ marginRight: 16 }}
                          onChange={(e) =>
                            setMaterialCola(e.target.value as string)
                          }
                          defaultValue={materialCola}
                        >
                          {materialOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel
                          id="number-select-label"
                          sx={{
                            backgroundColor: "white",
                            padding: "2px",
                          }}
                        >
                          N°
                        </InputLabel>
                        <Select
                          displayEmpty
                          variant="outlined"
                          sx={{ width: "90%" }}
                          style={{ marginRight: 16 }}
                          onChange={(e) =>
                            setNumberCola(e.target.value as string)
                          }
                          defaultValue={numberCola}
                        >
                          {numbers.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        label="Outros"
                        onChange={(e) =>
                          setOthersCola(e.target.value as string)
                        }
                        defaultValue={othersCola}
                      ></TextField>
                    </Box>
                  </>
                )}

                {aparateBrace.includes("INF") && (
                  <>
                    <Typography textAlign="left">Inferior</Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      mt={2}
                      mb={2}
                      width="100%"
                    >
                      <FormControl fullWidth>
                        <InputLabel
                          id="material-select-label"
                          sx={{
                            backgroundColor: "white",
                            padding: "2px",
                          }}
                        >
                          Material
                        </InputLabel>
                        <Select
                          displayEmpty
                          variant="outlined"
                          sx={{ width: "90%" }}
                          style={{ marginRight: 16 }}
                          onChange={(e) =>
                            setMaterialColaInf(e.target.value as string)
                          }
                          defaultValue={materialColaInf}
                        >
                          {materialOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel
                          id="number-select-label"
                          sx={{
                            backgroundColor: "white",
                            padding: "2px",
                          }}
                        >
                          N°
                        </InputLabel>
                        <Select
                          displayEmpty
                          variant="outlined"
                          sx={{ width: "90%" }}
                          style={{ marginRight: 16 }}
                          onChange={(e) =>
                            setNumberColaInf(e.target.value as string)
                          }
                          defaultValue={numberColaInf}
                        >
                          {numbers.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        label="Outros"
                        onChange={(e) =>
                          setOthersColaInf(e.target.value as string)
                        }
                        defaultValue={othersColaInf}
                      ></TextField>
                    </Box>
                  </>
                )}

                <Button
                  sx={{ margin: "20px 0" }}
                  onClick={() => setModalPlanningAparat(true)}
                  fullWidth
                >
                  Abrir Odontograma
                </Button>

                <Modal
                  open={modalPlanningAparat}
                  onClose={() => setModalPlanningAparat(false)}
                >
                  <Odontograma
                    onClose={() => setModalPlanningAparat(false)}
                    selected={odontogramaPlanningAparat}
                    setSelected={setOdontogramaPlanningAparat}
                    subtitle="Colagem de aparatologia ortodôntica - Planejamento"
                  />
                </Modal>
              </Box>
            )}
          </>
        )}
      </Styled.BoxIndication>

      <Styled.BoxIndication>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={wire}
                onClick={() => {
                  setWire(!wire);
                  setChangeWire([]); // Reseta o estado ao desmarcar
                }}
              />
            }
            label="Trocar Fio"
          />
        </FormGroup>
        {wire && (
          <>
            <Styled.BoxButtons>
              <Button
                variant={changeWire.includes("SUP") ? "contained" : "outlined"}
                onClick={() => {
                  if (changeWire.includes("SUP")) {
                    setChangeWire(changeWire.filter((type) => type !== "SUP"));
                  } else {
                    setChangeWire([...changeWire, "SUP"]);
                  }
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: changeWire.includes("SUP")
                      ? "#007DED"
                      : "white",
                    color: changeWire.includes("SUP") ? "white" : "#007DED",
                    borderColor: changeWire.includes("SUP")
                      ? "#007DED"
                      : "#EEEFF1",
                  },
                }}
              >
                Superior
              </Button>
              <Button
                variant={changeWire.includes("INF") ? "contained" : "outlined"}
                onClick={() => {
                  if (changeWire.includes("INF")) {
                    setChangeWire(changeWire.filter((type) => type !== "INF"));
                  } else {
                    setChangeWire([...changeWire, "INF"]);
                  }
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: changeWire.includes("INF")
                      ? "#007DED"
                      : "white",
                    color: changeWire.includes("INF") ? "white" : "#007DED",
                    borderColor: changeWire.includes("INF")
                      ? "#007DED"
                      : "#EEEFF1",
                  },
                }}
              >
                Inferior
              </Button>
            </Styled.BoxButtons>
            {changeWire.length > 0 && (
              <>
                {changeWire.includes("SUP") && (
                  <>
                    <Typography>Superior</Typography>
                    <Box display="flex" alignItems="center">
                      <FormControl fullWidth>
                        <InputLabel
                          id="material-select-label"
                          sx={{ backgroundColor: "white", padding: "2px" }}
                        >
                          Material
                        </InputLabel>
                        <Select
                          displayEmpty
                          variant="outlined"
                          sx={{ width: "90%" }}
                          style={{ marginRight: 16 }}
                          onChange={(e) =>
                            setMaterialChange(e.target.value as string)
                          }
                          value={materialChange}
                        >
                          {materialOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel
                          id="number-select-label"
                          sx={{ backgroundColor: "white", padding: "2px" }}
                        >
                          N°
                        </InputLabel>
                        <Select
                          displayEmpty
                          variant="outlined"
                          sx={{ width: "90%" }}
                          style={{ marginRight: 16 }}
                          onChange={(e) =>
                            setNumberChange(e.target.value as string)
                          }
                          value={numberChange}
                        >
                          {numbers.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <TextField
                        label="Outros"
                        onChange={(e) =>
                          setOthersChange(e.target.value as string)
                        }
                        value={othersChange}
                      />
                    </Box>
                  </>
                )}

                {changeWire.includes("INF") && (
                  <>
                    <Typography mt={2}>Inferior</Typography>
                    <Box display="flex" alignItems="center">
                      <FormControl fullWidth>
                        <InputLabel
                          id="material-select-label"
                          sx={{ backgroundColor: "white", padding: "2px" }}
                        >
                          Material
                        </InputLabel>
                        <Select
                          displayEmpty
                          variant="outlined"
                          sx={{ width: "90%" }}
                          style={{ marginRight: 16 }}
                          onChange={(e) =>
                            setMaterialChangeInf(e.target.value as string)
                          }
                          value={materialChangeInf}
                        >
                          {materialOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel
                          id="number-select-label"
                          sx={{ backgroundColor: "white", padding: "2px" }}
                        >
                          N°
                        </InputLabel>
                        <Select
                          displayEmpty
                          variant="outlined"
                          sx={{ width: "90%" }}
                          style={{ marginRight: 16 }}
                          onChange={(e) =>
                            setNumberChangeInf(e.target.value as string)
                          }
                          value={numberChangeInf}
                        >
                          {numbers.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <TextField
                        label="Outros"
                        onChange={(e) =>
                          setOthersChangeInf(e.target.value as string)
                        }
                        value={othersChangeInf}
                      />
                    </Box>
                  </>
                )}

                <Button
                  onClick={() => setModalChangeWire(true)}
                  sx={{ marginTop: "15px" }}
                >
                  Abrir Odontograma
                </Button>

                <Modal
                  open={modalChangeWire}
                  onClose={() => setModalChangeWire(false)}
                >
                  <Odontograma
                    onClose={() => setModalChangeWire(false)}
                    selected={odontogramaChangeWire}
                    setSelected={setOdontogramaChangeWire}
                    subtitle="Trocar Fio"
                  />
                </Modal>
              </>
            )}
          </>
        )}
      </Styled.BoxIndication>

      <Styled.BoxIndication>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={keepThread}
                onClick={() => {
                  setKeepThread(!keepThread);
                  setKeep([]); // Reseta o estado ao desmarcar
                }}
              />
            }
            label="Manter Fio"
          />
        </FormGroup>
        {keepThread && (
          <>
            <Styled.BoxButtons>
              <Button
                variant={keep.includes("SUP") ? "contained" : "outlined"}
                onClick={() => {
                  if (keep.includes("SUP")) {
                    setKeep(keep.filter((type) => type !== "SUP"));
                  } else {
                    setKeep([...keep, "SUP"]);
                  }
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: keep.includes("SUP") ? "#007DED" : "white",
                    color: keep.includes("SUP") ? "white" : "#007DED",
                    borderColor: keep.includes("SUP") ? "#007DED" : "#EEEFF1",
                  },
                }}
              >
                Superior
              </Button>
              <Button
                variant={keep.includes("INF") ? "contained" : "outlined"}
                onClick={() => {
                  if (keep.includes("INF")) {
                    setKeep(keep.filter((type) => type !== "INF"));
                  } else {
                    setKeep([...keep, "INF"]);
                  }
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: keep.includes("INF") ? "#007DED" : "white",
                    color: keep.includes("INF") ? "white" : "#007DED",
                    borderColor: keep.includes("INF") ? "#007DED" : "#EEEFF1",
                  },
                }}
              >
                Inferior
              </Button>
            </Styled.BoxButtons>
            {keep.length > 0 && (
              <Box display="flex" alignItems="center" mt={2}>
                <FormControl>
                  <RadioGroup
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                    onChange={(e) => setAtivation(e.target.value as string)}
                    value={ativation}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Com ativação"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="Sem ativação"
                    />
                  </RadioGroup>
                </FormControl>

                <TextField label="Outros"></TextField>
              </Box>
            )}
          </>
        )}
      </Styled.BoxIndication>

      <Styled.BoxIndication>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={ciment}
                onClick={() => {
                  setCiment(!ciment);
                  setCimentBand([]); // Reseta o estado ao desmarcar
                }}
              />
            }
            label="Cimentação de banda"
          />
        </FormGroup>

        {ciment && (
          <>
            <Styled.BoxButtons>
              <Button
                variant={cimentBand.includes("SUP") ? "contained" : "outlined"}
                onClick={() => {
                  if (cimentBand.includes("SUP")) {
                    setCimentBand(cimentBand.filter((type) => type !== "SUP"));
                  } else {
                    setCimentBand([...cimentBand, "SUP"]);
                  }
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: cimentBand.includes("SUP")
                      ? "#007DED"
                      : "white",
                    color: cimentBand.includes("SUP") ? "white" : "#007DED",
                    borderColor: cimentBand.includes("SUP")
                      ? "#007DED"
                      : "#EEEFF1",
                  },
                }}
              >
                Superior
              </Button>
              <Button
                variant={cimentBand.includes("INF") ? "contained" : "outlined"}
                onClick={() => {
                  if (cimentBand.includes("INF")) {
                    setCimentBand(cimentBand.filter((type) => type !== "INF"));
                  } else {
                    setCimentBand([...cimentBand, "INF"]);
                  }
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: cimentBand.includes("INF")
                      ? "#007DED"
                      : "white",
                    color: cimentBand.includes("INF") ? "white" : "#007DED",
                    borderColor: cimentBand.includes("INF")
                      ? "#007DED"
                      : "#EEEFF1",
                  },
                }}
              >
                Inferior
              </Button>
            </Styled.BoxButtons>

            {cimentBand.length > 0 && (
              <Button onClick={() => setModalCiment(true)}>
                Abrir Odontograma
              </Button>
            )}

            <Modal open={modalCiment} onClose={() => setModalCiment(false)}>
              <Odontograma
                onClose={() => setModalCiment(false)}
                selected={odontogramaCiment}
                setSelected={setOdontogramaCiment}
                subtitle="Cimentação de banda"
              />
            </Modal>
          </>
        )}
      </Styled.BoxIndication>

      <Styled.BoxIndication>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={glue}
                onClick={() => {
                  setGlue(!glue);
                  setGlueTube([]); // Reseta o estado ao desmarcar
                }}
              />
            }
            label="Colagem do tubo"
          />
        </FormGroup>

        {glue && (
          <>
            <Styled.BoxButtons>
              <Button
                variant={glueTube.includes("SUP") ? "contained" : "outlined"}
                onClick={() => {
                  if (glueTube.includes("SUP")) {
                    setGlueTube(glueTube.filter((type) => type !== "SUP"));
                  } else {
                    setGlueTube([...glueTube, "SUP"]);
                  }
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: glueTube.includes("SUP")
                      ? "#007DED"
                      : "white",
                    color: glueTube.includes("SUP") ? "white" : "#007DED",
                    borderColor: glueTube.includes("SUP")
                      ? "#007DED"
                      : "#EEEFF1",
                  },
                }}
              >
                Superior
              </Button>
              <Button
                variant={glueTube.includes("INF") ? "contained" : "outlined"}
                onClick={() => {
                  if (glueTube.includes("INF")) {
                    setGlueTube(glueTube.filter((type) => type !== "INF"));
                  } else {
                    setGlueTube([...glueTube, "INF"]);
                  }
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: glueTube.includes("INF")
                      ? "#007DED"
                      : "white",
                    color: glueTube.includes("INF") ? "white" : "#007DED",
                    borderColor: glueTube.includes("INF")
                      ? "#007DED"
                      : "#EEEFF1",
                  },
                }}
              >
                Inferior
              </Button>
            </Styled.BoxButtons>

            {glueTube.length > 0 && (
              <Button onClick={() => setModalCollage(true)}>
                Abrir Odontograma
              </Button>
            )}

            <Modal open={modalCollage} onClose={() => setModalCollage(false)}>
              <Odontograma
                onClose={() => setModalCollage(false)}
                selected={odontogramaCollage}
                setSelected={setOdontogramaCollage}
                subtitle="Colagem do tubo"
              />
            </Modal>
          </>
        )}
      </Styled.BoxIndication>

      <Styled.BoxIndication>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={complement}
                onClick={() => {
                  setComplement(!complement);
                  setComp([]);
                }}
              />
            }
            label="Aparelho Complementar"
          />
        </FormGroup>

        {complement && (
          <>
            <Autocomplete
              options={complementary}
              multiple
              sx={{ margin: "10px 0" }}
              onChange={(event, newValue) => {
                setComp(newValue);
              }}
              defaultValue={comp}
              renderInput={(params) => (
                <TextField
                  {...params}
                  defaultValue={comp}
                  label="Aparelho complementar"
                />
              )}
            />

            {/*comp.length > 0 && (
              <>
                <Button onClick={() => setModalComplement(true)}>
                  Abrir Odontograma
                </Button>
                <Modal
                  open={modalComplement}
                  onClose={() => setModalComplement(false)}
                >
                  <Odontograma
                    onClose={() => setModalComplement(false)}
                    selected={odontogramaComplement}
                    setSelected={setOdontogramaComplement}
                    subtitle="Aparelho Complementar"
                  />
                </Modal>
              </>
            )*/}
          </>
        )}
      </Styled.BoxIndication>

      <Styled.BoxIndication>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={othersProcedures}
                onClick={() => setOthersProcedures(!othersProcedures)}
              />
            }
            label="Outros procedimentos"
          />
        </FormGroup>

        {othersProcedures && (
          <>
            <Autocomplete
              options={proceduresData}
              multiple
              sx={{ margin: "10px 0" }}
              onChange={(event, newValue) => {
                setValueOthers(newValue);
              }}
              defaultValue={valueOthers}
              renderInput={(params) => (
                <TextField
                  {...params}
                  defaultValue={valueOthers}
                  label="Outros procedimentos"
                />
              )}
            />
            {/* 
            <Styled.ContentComplement>
              {procedureOptions.map((option) => (
                <Styled.AllComplements key={option.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedProcedureOptions.includes(
                          option.value
                        )}
                        onChange={() =>
                          handleOptionChange(
                            option.value,
                            setSelectedProcedureOptions
                          )
                        }
                      />
                    }
                    label={option.label}
                  />
                </Styled.AllComplements>
              ))}
            </Styled.ContentComplement>
            */}
          </>
        )}
      </Styled.BoxIndication>

      <Styled.BoxIndication>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={othersReferrals}
                onClick={() => setOthersReferrals(!othersReferrals)}
              />
            }
            label="Encaminhamentos"
          />
        </FormGroup>

        {othersReferrals && (
          <>
            <Autocomplete
              options={referralsData}
              multiple
              sx={{ margin: "10px 0" }}
              onChange={(event, newValue) => {
                setValueEnc(newValue);
              }}
              defaultValue={valueEnc}
              renderInput={(params) => (
                <TextField
                  {...params}
                  defaultValue={valueEnc}
                  label="Encaminhamentos"
                />
              )}
            />
          </>
        )}
      </Styled.BoxIndication>

      <Styled.BoxIndication>
        <TextField label="Observações gerais" fullWidth margin="normal" />
      </Styled.BoxIndication>

      <Styled.BtnFixed>
        <Button fullWidth onClick={() => setStep(3)} variant="outlined">
          Voltar
        </Button>
        <Button fullWidth onClick={next}>
          Continuar
        </Button>
      </Styled.BtnFixed>
    </Styled.Box>
  );
};

export { PlanningFixed };
