import styled from "styled-components";

export const ContainerPictures = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 80vh;
  overflow: scroll;
  margin-top: 25px;

  a {
    color: #000;
  }
`;
