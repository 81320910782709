import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface IProps {
  title?: string;
  text: string[];
  buttonText?: string;
  open: boolean;
  onClose: () => void;
}

const AlertDialog: React.FC<IProps> = ({
  buttonText,
  open,
  text,
  title,
  onClose,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: "bold" }}>
          {title || "Aviso"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "black" }}
          >
            {text.join("\n")}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Button onClick={onClose} autoFocus>
            {buttonText || "Ok"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export { AlertDialog };
