import React from "react";

import { FirstProvider } from "shared/providers/FirstConsult";
import { ConsultStepper } from "./components/stepper";
import { Container } from "@mui/material";

const FirstConsult: React.FC = () => {
  return (
    <FirstProvider>
      <Container maxWidth="xl">
        <ConsultStepper />
      </Container>
    </FirstProvider>
  );
};

export { FirstConsult };
