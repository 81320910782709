import React from "react";
import * as Styled from "./styles";
import {
  odontogramaInferior,
  odontogramaSuperior,
} from "shared/data/odontograma";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { ReturnButton } from "components/ReturnButton";
import { getClinicalRecords } from "requests/ClinicalRecord";
import { useAuth } from "shared/providers/auth";
import { useParams } from "react-router-dom";
import { IoIosArrowUp } from "react-icons/io";

interface IProps {
  idPacientParam?: number;
  onClick?: () => void;
}

interface OdontItem {
  tooth: number;
  procedure: string;
}

interface GroupedByProcedure {
  [key: string]: OdontItem[];
}

const OdontogramaUser: React.FC<IProps> = ({ onClick, idPacientParam }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useAuth();

  const [returnOdont, setReturnOdont] = React.useState<GroupedByProcedure>(
    {} as GroupedByProcedure
  );

  const { idPacient } = useParams();

  const getOdontogramaAll = async () => {
    setLoading(true);

    try {
      setLoading(false);
      const { status, data } = await getClinicalRecords({
        userId: user.data.id,
        idPac: Number(
          idPacientParam && idPacientParam > 0 ? idPacientParam : idPacient
        ),
      });

      if ([200, 201].includes(status)) {
        const groupedByProcedure: any = {};

        data.data.forEach((item) => {
          if (item.odont && item.odont.length > 0) {
            item.odont.forEach((odontItem) => {
              const procedure: any = `${odontItem.procedure} - ${item.appointment}`;

              if (!groupedByProcedure[procedure]) {
                groupedByProcedure[procedure] = [];
              }

              groupedByProcedure[procedure].push(odontItem);
            });
          }
        });

        setReturnOdont(groupedByProcedure);
      } else {
        throw new Error("Erro ao carregar as fotos.");
      }
    } catch (e: any) {
      setLoading(false);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getOdontogramaAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ backgroundColor: "white", height: "100%", width: "100%" }}>
      <Container>
        <ReturnButton title="Odontograma" onClick={onClick} />

        {loading ? (
          <CircularProgress />
        ) : (
          <Styled.BoxScroll>
            {Object.keys(returnOdont).length > 0 ? (
              Object.keys(returnOdont).map((procedure) => (
                <>
                  <Accordion key={procedure} sx={{ marginTop: "30px" }}>
                    <AccordionSummary
                      expandIcon={<IoIosArrowUp />}
                      aria-controls={String(procedure)}
                      id={String(procedure)}
                      sx={{ fontWeight: "bold" }}
                    >
                      {procedure}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Styled.Odontograma>
                        <Styled.Superiores>
                          {odontogramaSuperior.map((dado) => (
                            <Styled.Box
                              key={dado.id}
                              selected={
                                !!returnOdont[procedure].find(
                                  (item) => item.tooth === dado.id
                                )
                              }
                            >
                              <dado.img />
                              <Typography>{dado.id}</Typography>
                            </Styled.Box>
                          ))}
                        </Styled.Superiores>
                        <Styled.Inferiores>
                          {odontogramaInferior.map((dado) => (
                            <Styled.Box
                              key={dado.id}
                              selected={
                                !!returnOdont[procedure].find(
                                  (item) => item.tooth === dado.id
                                )
                              }
                            >
                              <Typography>{dado.id}</Typography>
                              <dado.img />
                            </Styled.Box>
                          ))}
                        </Styled.Inferiores>
                      </Styled.Odontograma>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))
            ) : (
              <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                  Não há odontograma para este paciente.
                </Typography>
              </Box>
            )}
          </Styled.BoxScroll>
        )}
      </Container>
    </Box>
  );
};

export { OdontogramaUser };

/*  

<Styled.Odontograma>
          <Styled.Superiores>
            {odontogramaSuperior.map((dado) => (
              <Styled.Box
                key={dado.id}
                
                selected={!!selected.find((item) => item.tooth === dado.id)}
              >
                <dado.img />
                <Typography>{dado.id}</Typography>
              </Styled.Box>
            ))}
          </Styled.Superiores>
          <Styled.Inferiores>
            {odontogramaInferior.map((dado) => (
              <Styled.Box
                key={dado.id}
                
                selected={!!selected.find((item) => item.tooth === dado.id)}
              >
                <Typography>{dado.id}</Typography>
                <dado.img />
              </Styled.Box>
            ))}
          </Styled.Inferiores>
        </Styled.Odontograma>
*/
