/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import * as Styled from "./styles";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
} from "@mui/material";
import { useAuth } from "shared/providers/auth";

import { ReactComponent as CalendarIcon } from "shared/assets/svg/calendar.svg";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { SlRefresh } from "react-icons/sl";

const FilterList: React.FC = () => {
  const { setFreeTime, freeTime, schedules, user } = useAuth();
  const [timeSelect, setTimeSelect] = React.useState<string>("");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [isRotating, setIsRotating] = React.useState<boolean>(false);

  const [timeChoosen, setTimeChoosen] = React.useState<string>("");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const date = new Date();
  const months = [
    "JANEIRO",
    "FEVEREIRO",
    "MARÇO",
    "ABRIL",
    "MAIO",
    "JUNHO",
    "JULHO",
    "AGOSTO",
    "SETEMBRO",
    "OUTUBRO",
    "NOVEMBRO",
    "DEZEMBRO",
  ];

  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const formattedDate = `${day} ${months[month]}, ${year}`;

  function formatDate(dateStr: string) {
    const parts = dateStr.split("/");
    const day = parts[0];
    const monthIndex = parseInt(parts[1], 10) - 1;
    const year = parts[2];

    const date = `${day} ${months[monthIndex]}, ${year}`;

    setTimeSelect(date);

    const [daySc, month, yearSc] = dateStr.split("/");

    const dateSc = `${yearSc}-${month}-${daySc}`;

    setTimeChoosen(dateSc);

    schedules({ id: user.data.id, date: dateSc });

    return date;
  }

  const refresh = React.useCallback(() => {
    const data = new Date();
    const ano = data.getFullYear();
    const mes = String(data.getMonth() + 1).padStart(2, "0");
    const dia = String(data.getDate()).padStart(2, "0");
    const dataFormatada = `${ano}-${mes}-${dia}`;

    schedules({
      id: user && user.data && user.data.id ? user.data.id : "0",
      date: timeChoosen.length > 0 ? timeChoosen : dataFormatada,
    });

    setIsRotating(true);
    setTimeout(() => setIsRotating(false), 1000);
  }, [schedules, user]);

  React.useEffect(() => {
    refresh();
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      refresh();
    }, 600000);

    return () => clearInterval(intervalId);
  }, [refresh]);

  return (
    <>
      <Styled.Content>
        <Styled.CalendarBox>
          <button
            onClick={handleClick}
            style={{
              backgroundColor: "transparent",
              border: "none",
              display: "flex",
              flexDirection: "row",
              gap: "5px",
            }}
          >
            <CalendarIcon width="25px" height="25px" />
            <p>{timeSelect.length > 1 ? timeSelect : formattedDate}</p>
          </button>
        </Styled.CalendarBox>

        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <FormGroup>
            <FormControlLabel
              sx={{ flexDirection: "row-reverse" }}
              control={<Checkbox defaultChecked />}
              label="Mostrar horários vagos"
              onClick={() => setFreeTime(!freeTime)}
            />
          </FormGroup>
          <Button onClick={refresh}>
            <SlRefresh size={28} className={isRotating ? "rotate" : ""} />
          </Button>
        </Box>
      </Styled.Content>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <DateCalendar
            onChange={(item) => {
              handleClose();
              formatDate(dayjs(item).format("DD/MM/YYYY"));
            }}
          />
        </LocalizationProvider>
      </Menu>
    </>
  );
};

export { FilterList };
