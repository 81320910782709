import { ReactComponent as sup11 } from "shared/assets/superiores/11.svg";
import { ReactComponent as sup12 } from "shared/assets/superiores/12.svg";
import { ReactComponent as sup13 } from "shared/assets/superiores/13.svg";
import { ReactComponent as sup14 } from "shared/assets/superiores/14.svg";
import { ReactComponent as sup15 } from "shared/assets/superiores/15.svg";
import { ReactComponent as sup16 } from "shared/assets/superiores/16.svg";
import { ReactComponent as sup17 } from "shared/assets/superiores/17.svg";
import { ReactComponent as sup18 } from "shared/assets/superiores/18.svg";
import { ReactComponent as sup21 } from "shared/assets/superiores/21.svg";
import { ReactComponent as sup22 } from "shared/assets/superiores/22.svg";
import { ReactComponent as sup23 } from "shared/assets/superiores/23.svg";
import { ReactComponent as sup24 } from "shared/assets/superiores/24.svg";
import { ReactComponent as sup25 } from "shared/assets/superiores/25.svg";
import { ReactComponent as sup26 } from "shared/assets/superiores/26.svg";
import { ReactComponent as sup27 } from "shared/assets/superiores/27.svg";
import { ReactComponent as sup28 } from "shared/assets/superiores/28.svg";

import { ReactComponent as inf31 } from "shared/assets/inferiores/31.svg";
import { ReactComponent as inf32 } from "shared/assets/inferiores/32.svg";
import { ReactComponent as inf33 } from "shared/assets/inferiores/33.svg";
import { ReactComponent as inf34 } from "shared/assets/inferiores/34.svg";
import { ReactComponent as inf35 } from "shared/assets/inferiores/35.svg";
import { ReactComponent as inf36 } from "shared/assets/inferiores/36.svg";
import { ReactComponent as inf37 } from "shared/assets/inferiores/37.svg";
import { ReactComponent as inf38 } from "shared/assets/inferiores/38.svg";
import { ReactComponent as inf41 } from "shared/assets/inferiores/41.svg";
import { ReactComponent as inf42 } from "shared/assets/inferiores/42.svg";
import { ReactComponent as inf43 } from "shared/assets/inferiores/43.svg";
import { ReactComponent as inf44 } from "shared/assets/inferiores/44.svg";
import { ReactComponent as inf45 } from "shared/assets/inferiores/45.svg";
import { ReactComponent as inf46 } from "shared/assets/inferiores/46.svg";
import { ReactComponent as inf47 } from "shared/assets/inferiores/47.svg";
import { ReactComponent as inf48 } from "shared/assets/inferiores/48.svg";

export const odontogramaSuperior = [
  { id: 11, img: sup11 },
  { id: 12, img: sup12 },
  { id: 13, img: sup13 },
  { id: 14, img: sup14 },
  { id: 15, img: sup15 },
  { id: 16, img: sup16 },
  { id: 17, img: sup17 },
  { id: 18, img: sup18 },
  { id: 21, img: sup21 },
  { id: 22, img: sup22 },
  { id: 23, img: sup23 },
  { id: 24, img: sup24 },
  { id: 25, img: sup25 },
  { id: 26, img: sup26 },
  { id: 27, img: sup27 },
  { id: 28, img: sup28 },
];

export const odontogramaInferior = [
  { id: 31, img: inf31 },
  { id: 32, img: inf32 },
  { id: 33, img: inf33 },
  { id: 34, img: inf34 },
  { id: 35, img: inf35 },
  { id: 36, img: inf36 },
  { id: 37, img: inf37 },
  { id: 38, img: inf38 },
  { id: 41, img: inf41 },
  { id: 42, img: inf42 },
  { id: 43, img: inf43 },
  { id: 44, img: inf44 },
  { id: 45, img: inf45 },
  { id: 46, img: inf46 },
  { id: 47, img: inf47 },
  { id: 48, img: inf48 },
];
