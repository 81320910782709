import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 98vh;
  background-color: #00357b;
  padding: 20px;

  button {
    position: fixed;
    width: 95%;
    bottom: 20px;
  }

  @media (max-width: 768px) {
    svg {
      width: 400px;
      height: 200px;
    }
  }

  @media (min-width: 1030px) {
    flex-direction: row;
  }
`;

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: white;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const Subtitle = styled.h3`
  color: white;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
