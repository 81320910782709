import React from "react";

import * as Styled from "./styles";
import { useFirstConsult } from "shared/providers/FirstConsult";
import { Box, Button, Modal, Typography } from "@mui/material";
import { ComplementData } from "shared/mock";
import { questions } from "shared/data/question";

import SignatureCanvas from "react-signature-canvas";
import { useNavigate } from "react-router-dom";
import {
  INextAppointment,
  IOdont,
  ISendSchedule,
} from "shared/types/SendSchedule";
import { useAuth } from "shared/providers/auth";
import { sendSchedule } from "requests/SendSchedule";
import toast from "react-hot-toast";
import { AlertDialog } from "components/Dialog";
import { IAnamneseData } from "shared/types/Anamnese";
import { postAnamnese } from "requests/Anamnese";
import { getOrtos } from "requests/Printing";

const ClinicalRecord: React.FC = () => {
  const navigate = useNavigate();
  const { user, responses } = useAuth();
  const {
    diagnosisDescription,
    brace,
    checkedState,
    btnCirurgy,
    btnOrtognatica,
    btnPatients,
    btnOthers,
    answers,
    observations,
    selectedOptions,
    needsBand,
    performMolding,
    moldingJustification,
    needsSeparator,
    othersProcedures,
    valueOthers,
    othersReferrals,
    valueEnc,
    selectedDate,
    selectedTime,
    selectedHour,
    setStep,
    braceType,
    painPrescriptionNeeded,
    informedBasicCare,
    deliveredTreatmentManual,
    advisedWireAndWax,
    additionalDevice,
    otherProceduresValue,
    hasOtherProcedures,
    hasOtherReferrals,
    referralValue,
    numberProcedure,
    materialProcedure,
    othersProceduresFixed,
    ciment,
    cimentBand,
    glue,
    glueTube,
    rep,
    repOrRec,
    repOrRecBrace,
    complement,
    comp,
    aparate,
    aparateBrace,
    materialCola,
    numberCola,
    othersCola,
    wire,
    changeWire,
    materialChange,
    numberChange,
    othersChange,
    keepThread,
    keep,
    ativation,
    patientObj,
    odontogramaCiment,
    odontogramaCollage,
    odontogramaComplement,
    odontogramaAparat,
    painPrescriptionObservations,
    basicCareObservations,
    treatmentManualObservations,
    wireAndWaxObservations,
    bandaExists,
    bandaId,
    selectedDateBand,
    selectedHourBand,
    selectedTimeBand,
    selectedProceduresId,
    onlyBand,
    separatorId,
    aparateReturn,
    aparateBraceReturn,
    compReturn,
    glueReturn,
    keepReturn,
    valueEncReturn,
    othersReferralsReturn,
    valueOthersReturn,
    othersProceduresReturn,
    complementReturn,
    glueTubReturn,
    cimentBandReturn,
    cimentReturn,
    ativationReturn,
    keepThreadReturn,
    numberChangeReturn,
    materialChangeReturn,
    changeWireReturn,
    wireReturn,
    numberReturn,
    materialReturn,
    mostImportantType,
    othersReturn,
    othersChangeReturn,
    justify01,
    justify02,
    justify03,
    justify04,
    justify05,
    justify07,
    justify08,
    justify09,
    justify15,
    justify16,

    realize,
    justification,

    realizeContent,
    justificationContent,
    informateRegionContent,

    realizeAparat,
    justificationAparat,

    realizeEruption,
    justificationEruption,
    informateRegionEruption,

    realizeIntermaxilary,
    justificationIntermaxilary,

    realizeCollage,
    justificationCollage,

    realizeRebonding,
    justificationRebonding,

    realizeBite,
    justificationBite,

    route,
    justifyRoute,

    realizeRelease,
    justificationRelease,

    currentProcedureIndex,

    odontogramaLevant,
    odontogramaIntermax,
    odontogramaButton,
    odontogramaInstall,
    odontogramaRemote,

    odontogramaRemoteReturn,
    odontogramaInstallReturn,
    odontogramaButtonReturn,
    odontogramaIntermaxReturn,
    odontogramaLevantReturn,

    justificationReturn,
    realizeReturn,
    realizeContentReturn,
    realizeAparatReturn,
    realizeEruptionReturn,
    realizeIntermaxilaryReturn,
    realizeCollageReturn,
    realizeRebondingReturn,
    realizeBiteReturn,
    routeReturn,
    justifyRouteReturn,
    realizeReleaseReturn,
    justificationContentReturn,
    informateRegionContentReturn,
    justificationAparatReturn,
    justificationEruptionReturn,
    informateRegionEruptionReturn,
    justificationIntermaxilaryReturn,
    justificationCollageReturn,
    justificationRebondingReturn,
    justificationBiteReturn,
    justificationReleaseReturn,
    proceduresLastAttendance,
    currentProcedureIndexReturn,
    odontogramaPlanningAparat,

    braceTypeMaintenance,
    materialProcedureMaintenance,
    numberProcedureMaintenance,
    othersProceduresFixedMaintenance,
    repOrRecProcedure,
    repOrRecBraceProcedure,
    painPrescriptionNeededMaintenance,
    basicCareObservationsMaintenance,
    informedBasicCareMaintenance,
    braceTypeMaintenanceReturn,
    repOrRecProcedureReturn,
    repOrRecBraceProcedureReturn,
    painPrescriptionNeededMaintenanceReturn,
    informedBasicCareMaintenanceReturn,
    materialProcedureMaintenanceReturn,
    numberProcedureMaintenanceReturn,
    othersProceduresFixedMaintenanceReturn,
    basicCareObservationsMaintenanceReturn,
    obsDiagnosis,
    odontogramaSeparator,
    odontogramaCimentProcedures,
    odontogramaCollageProcedures,
    hasComplement,
    odontogramaRepOrRec,
    wireProcedures,
    changeWireProcedures,
    materialChangeProcedures,
    numberChangeProcedures,
    othersChangeProcedures,
    keepThreadProcedures,
    keepProcedures,
    ativationProcedures,
    cimentProcedures,
    cimentBandProcedures,
    glueProcedures,
    glueTubProcedures,
    braceMovel,
    relizeMoldage,
    infMovable,
    supMovable,
    //installMovable,
    //moldageSecondMovable,
    //infMovableProcedure,
    //supMovableProcedure,
    moldageSecondMovableReturn,
    infMovableProcedureReturn,
    supMovableProcedureReturn,
    installMovableReturn,
    receiveHigh,
    othersColaInf,
    materialColaInf,
    numberColaInf,

    othersChangeInf,
    materialChangeInf,
    numberChangeInf,
    noCirurgyDiagnosis,
    materialReturnInf,
    numberReturnInf,
    othersReturnInf,
    materialChangeReturnInf,
    numberChangeReturnInf,
    othersChangeReturnInf,
    materialChangeProceduresInf,
    numberChangeProceduresInf,
    othersChangeProceduresInf,

    materialProceduresInf,
    numberProceduresInf,
    othersProceduresInf,
  } = useFirstConsult();

  const [isBtnFixedVisible, setIsBtnFixedVisible] =
    React.useState<boolean>(true);

  const [signatureDoctor, setSignatureDoctor] = React.useState<any>();
  const [signaturePatient, setSignaturePatient] = React.useState<any>();

  const selectedItems = Object.keys(checkedState)
    .filter((key: any) => checkedState[key])
    .map((key: any) => ComplementData[key]);

  const answeredYes = questions.filter(
    (question) => answers[question.questionText] === "sim"
  );

  const answeredNo = questions.filter(
    (question) => answers[question.questionText] === "não"
  );

  const dateStr: string = String(selectedDate);

  const date: Date = new Date(dateStr);

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone: "America/Sao_Paulo",
  };

  const formattedDate: string = date.toLocaleDateString("pt-BR", options);

  const formattedDateUTC = () => {
    const date = formattedDate.split("/");

    return `${date[2]}-${date[1]}-${date[0]}`;
  };

  const dateStrBand: string = String(selectedDateBand);

  const dateBand: Date = new Date(dateStrBand);

  const optionsBand: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone: "America/Sao_Paulo",
  };

  const formattedDateBand: string = dateBand.toLocaleDateString(
    "pt-BR",
    optionsBand
  );

  const formattedDateUTCBand = () => {
    const date = formattedDateBand.split("/");

    return `${date[2]}-${date[1]}-${date[0]}`;
  };

  const sigCanvasPatient = React.useRef<SignatureCanvas>(null);

  const saveSignaturePatient = () => {
    if (sigCanvasPatient.current) {
      const dataURL = sigCanvasPatient.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setSignaturePatient(dataURL);
    }
  };

  const sigCanvasDentist = React.useRef<SignatureCanvas>(null);

  const saveSignatureDentist = () => {
    if (sigCanvasDentist.current) {
      const dataURL = sigCanvasDentist.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setSignatureDoctor(dataURL);
    }
  };

  const clearSignaturePatient = () => {
    sigCanvasPatient.current?.clear();
    setSignaturePatient(null);
  };

  const clearSignatureDentits = () => {
    sigCanvasDentist.current?.clear();
    setSignatureDoctor(null);
  };

  const downloadPdf = async (referralValue: number, namePdf: string) => {
    try {
      const { data, status } = await getOrtos(
        user.data.id,
        patientObj.pac_id,
        referralValue
      );
      if (status === 200 && data && data.data) {
        // Supondo que data.data seja uma string Base64
        const base64Data = data.data;

        //@ts-ignore
        if (window && window.ReactNativeWebView) {
          //call rn to download
          //@ts-ignore
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              action: "downloadBase64File",
              value: base64Data,
            })
          );
        } else {
          const byteCharacters = atob(base64Data); // Decodifica a string Base64
          const byteNumbers = new Uint8Array(byteCharacters.length);

          // Converte a string em um array de bytes
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          // Cria um Blob com o tipo de PDF
          const blob = new Blob([byteNumbers], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob); // Cria um URL para o Blob

          // Cria um link para download
          const a = document.createElement("a");
          a.href = url;
          a.download = `${namePdf}.pdf`; // Nome do arquivo que será baixado
          document.body.appendChild(a);
          a.click(); // Simula o clique no link
          document.body.removeChild(a); // Remove o link do DOM
          window.URL.revokeObjectURL(url); // Libera a URL
        }
      } else {
        console.error("Erro ao obter os dados:", status);
      }

      // O restante do código para baixar o PDF permanece o mesmo
    } catch (error) {
      console.error("Erro na chamada da API:", error);
    }
  };

  const combineAndFilterDuplicates = (
    referrals: { label: string; value: number }[],
    valueEnc: { label: string; value: number }[],
    valueEncReturn: { label: string; value: number }[]
  ) => {
    const combined = [...referrals, ...valueEnc, ...valueEncReturn];

    const uniqueValues = new Set<number>();
    const uniqueCombined = combined.filter((item) => {
      if (!uniqueValues.has(item.value)) {
        uniqueValues.add(item.value);
        return true;
      }
      return false;
    });

    return uniqueCombined;
  };

  const createReferralButtons = () => {
    const uniqueCombined = combineAndFilterDuplicates(
      referralValue,
      valueEnc,
      valueEncReturn
    );

    return uniqueCombined.map(({ label, value }) => (
      <Button
        key={value.toString()} // Converte o número para string
        variant="contained"
        color="primary"
        onClick={() => downloadPdf(value, label)}
        fullWidth
      >
        {label}
      </Button>
    ));
  };

  const referralButtons = createReferralButtons();

  const adjustmentAcentuation = (value: string) => {
    if (value === "Instalacao de aparelho movel") {
      return "Instalação de aparelho móvel";
    } else {
      return value;
    }
  };

  const handlePrint = () => {
    setIsBtnFixedVisible(false);
    setTimeout(() => {
      //@ts-ignore
      if (window && window.ReactNativeWebView) {
        //@ts-ignore
        const htmlContent: any = document.querySelector(".printable").innerHTML;

        //call rn to download
        //@ts-ignore
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ action: "printContent", value: htmlContent })
        );
      } else {
        window.print();
      }

      setIsBtnFixedVisible(true);
    }, 0);
  };

  const getCombinedPayload = (): IOdont[] => {
    return [
      ...odontogramaCiment,
      ...odontogramaCollage,
      ...odontogramaComplement,
      ...odontogramaAparat,
      ...odontogramaLevant,
      ...odontogramaIntermax,
      ...odontogramaButton,
      ...odontogramaInstall,
      ...odontogramaRemote,
      ...odontogramaRemoteReturn,
      ...odontogramaInstallReturn,
      ...odontogramaButtonReturn,
      ...odontogramaIntermaxReturn,
      ...odontogramaLevantReturn,
      ...odontogramaPlanningAparat,
      ...odontogramaSeparator,
      ...odontogramaCimentProcedures,
      ...odontogramaCollageProcedures,
      ...odontogramaRepOrRec,
    ];
  };

  const odont: IOdont[] = getCombinedPayload();

  const nextAppointment: INextAppointment[] = [
    aparate &&
      aparateBrace.includes("SUP") && {
        id: "PPQ001",
        position: 1,
        ...(othersCola ? { description: othersCola } : {}),
        metaData: {
          wire:
            materialCola === "Niti"
              ? 1
              : materialCola === "Aço"
              ? 2
              : materialCola === "Niti Curva Reversa"
              ? 3
              : materialCola === "Aço Curva Reversa"
              ? 4
              : "",
          type:
            numberCola === "12 Redondo"
              ? 1
              : numberCola === "14 Redondo"
              ? 2
              : numberCola === "16 Redondo"
              ? 3
              : numberCola === "18 Redondo"
              ? 4
              : numberCola === "20 Redondo"
              ? 5
              : numberCola === "16x22 Retangular"
              ? 6
              : numberCola === "17x25 Retangular"
              ? 7
              : numberCola === "18x25 Retangular"
              ? 8
              : numberCola === "19x25 Retangular"
              ? 9
              : "",
        },
      },

    aparate &&
      aparateBrace.includes("INF") && {
        id: "PPQ001",
        position: 0,
        ...(othersColaInf ? { description: othersColaInf } : {}),
        metaData: {
          wire:
            materialColaInf === "Niti"
              ? 1
              : materialColaInf === "Aço"
              ? 2
              : materialColaInf === "Niti Curva Reversa"
              ? 3
              : materialColaInf === "Aço Curva Reversa"
              ? 4
              : "",
          type:
            numberColaInf === "12 Redondo"
              ? 1
              : numberColaInf === "14 Redondo"
              ? 2
              : numberColaInf === "16 Redondo"
              ? 3
              : numberColaInf === "18 Redondo"
              ? 4
              : numberColaInf === "20 Redondo"
              ? 5
              : numberColaInf === "16x22 Retangular"
              ? 6
              : numberColaInf === "17x25 Retangular"
              ? 7
              : numberColaInf === "18x25 Retangular"
              ? 8
              : numberColaInf === "19x25 Retangular"
              ? 9
              : "",
        },
      },

    wire &&
      changeWire.includes("SUP") && {
        id: "PPQ002",
        position: 1,
        ...(othersChange ? { description: othersChange } : {}),

        metaData: {
          wire:
            materialChange === "Niti"
              ? 1
              : materialChange === "Aço"
              ? 2
              : materialChange === "Niti Curva Reversa"
              ? 3
              : materialChange === "Aço Curva Reversa",
          type:
            numberChange === "12 Redondo"
              ? 1
              : numberChange === "14 Redondo"
              ? 2
              : numberChange === "16 Redondo"
              ? 3
              : numberChange === "18 Redondo"
              ? 4
              : numberChange === "20 Redondo"
              ? 5
              : numberChange === "16x22 Retangular"
              ? 6
              : numberChange === "17x25 Retangular"
              ? 7
              : numberChange === "18x25 Retangular"
              ? 8
              : numberChange === "19x25 Retangular"
              ? 9
              : "",
        },
      },

    wire &&
      changeWire.includes("INF") && {
        id: "PPQ002",
        position: 0,
        ...(othersChangeInf ? { description: othersChangeInf } : {}),

        metaData: {
          wire:
            materialChangeInf === "Niti"
              ? 1
              : materialChangeInf === "Aço"
              ? 2
              : materialChangeInf === "Niti Curva Reversa"
              ? 3
              : materialChangeInf === "Aço Curva Reversa",
          type:
            numberChangeInf === "12 Redondo"
              ? 1
              : numberChangeInf === "14 Redondo"
              ? 2
              : numberChangeInf === "16 Redondo"
              ? 3
              : numberChangeInf === "18 Redondo"
              ? 4
              : numberChangeInf === "20 Redondo"
              ? 5
              : numberChangeInf === "16x22 Retangular"
              ? 6
              : numberChangeInf === "17x25 Retangular"
              ? 7
              : numberChangeInf === "18x25 Retangular"
              ? 8
              : numberChangeInf === "19x25 Retangular"
              ? 9
              : "",
        },
      },

    keepThread && {
      id: "PPQ003",
      position:
        keep.includes("INF") && keep.includes("SUP")
          ? 3
          : keep.includes("INF")
          ? 0
          : keep.includes("SUP")
          ? 1
          : "",

      activation: ativation === "yes" ? 1 : 0,
      metaData: {},
    },

    ciment && {
      id: "PPQ004",
      position:
        cimentBand.includes("INF") && cimentBand.includes("SUP")
          ? 3
          : cimentBand.includes("INF")
          ? 0
          : cimentBand.includes("SUP")
          ? 1
          : "",
      metaData: {},
    },

    glue && {
      id: "PPQ005",
      position:
        glueTube.includes("INF") && glueTube.includes("SUP")
          ? 3
          : glueTube.includes("INF")
          ? 0
          : glueTube.includes("SUP")
          ? 1
          : "",
      metaData: {},
    },

    complement && {
      id: "PPQ007",
      position: 0,
      description: `${JSON.stringify(comp.map((item) => item))}`,
    },

    othersProcedures && {
      id: "PPQ008",
      description: `${JSON.stringify(valueOthers.map((item: any) => item))}`,
    },

    othersReferrals && {
      id: "PPQ009",
      url: "",
      description: `${JSON.stringify(valueEnc.map((item: any) => item))}`,
    },

    braceMovel && {
      id: "PPQ015",
      position:
        infMovable && supMovable ? 3 : infMovable && !supMovable ? 0 : 1,
    },

    relizeMoldage && {
      id: "PPQ016",
      position:
        infMovable && supMovable ? 3 : infMovable && !supMovable ? 0 : 1,
    },
  ].filter(Boolean) as INextAppointment[];

  const nextAppointmentReturn: INextAppointment[] = [
    aparateReturn && {
      id: "PPQ001",
      position:
        aparateBraceReturn.includes("INF") && aparateBraceReturn.includes("SUP")
          ? 3
          : aparateBraceReturn.includes("INF")
          ? 0
          : aparateBraceReturn.includes("SUP")
          ? 1
          : "",
      description: othersReturn,

      metaData: {
        wire:
          materialReturn === "Niti"
            ? 1
            : materialReturn === "Aço"
            ? 2
            : materialReturn === "Niti Curva Reversa"
            ? 3
            : materialReturn === "Aço Curva Reversa"
            ? 4
            : "",
        type:
          numberReturn === "12 Redondo"
            ? 1
            : numberReturn === "14 Redondo"
            ? 2
            : numberReturn === "16 Redondo"
            ? 3
            : numberReturn === "18 Redondo"
            ? 4
            : numberReturn === "20 Redondo"
            ? 5
            : numberReturn === "16x22 Retangular"
            ? 6
            : numberReturn === "17x25 Retangular"
            ? 7
            : numberReturn === "18x25 Retangular"
            ? 8
            : numberReturn === "19x25 Retangular"
            ? 9
            : "",
        justification: justify01,
      },
    },

    wireReturn && {
      id: "PPQ002",
      position:
        changeWireReturn.includes("INF") && changeWireReturn.includes("SUP")
          ? 3
          : changeWireReturn.includes("INF")
          ? 0
          : changeWireReturn.includes("SUP")
          ? 1
          : "",
      description: othersChangeReturn,
      metaData: {
        wire:
          materialChangeReturn === "Niti"
            ? 1
            : materialChangeReturn === "Aço"
            ? 2
            : materialChangeReturn === "Niti Curva Reversa"
            ? 3
            : materialChangeReturn === "Aço Curva Reversa"
            ? 4
            : "",
        type:
          numberChangeReturn === "12 Redondo"
            ? 1
            : numberChangeReturn === "14 Redondo"
            ? 2
            : numberChangeReturn === "16 Redondo"
            ? 3
            : numberChangeReturn === "18 Redondo"
            ? 4
            : numberChangeReturn === "20 Redondo"
            ? 5
            : numberChangeReturn === "16x22 Retangular"
            ? 6
            : numberChangeReturn === "17x25 Retangular"
            ? 7
            : numberChangeReturn === "18x25 Retangular"
            ? 8
            : numberChangeReturn === "19x25 Retangular"
            ? 9
            : "",
        justification: justify02,
      },
    },

    keepThreadReturn && {
      id: "PPQ003",
      position:
        keepReturn.includes("INF") && keepReturn.includes("SUP")
          ? 3
          : keepReturn.includes("INF")
          ? 0
          : keepReturn.includes("SUP")
          ? 1
          : "",
      activation: ativationReturn === "yes" ? 1 : 0,

      metaData: {
        justification: justify03,
      },
    },

    cimentReturn && {
      id: "PPQ004",
      position:
        cimentBandReturn.includes("INF") && cimentBandReturn.includes("SUP")
          ? 3
          : cimentBandReturn.includes("INF")
          ? 0
          : cimentBandReturn.includes("SUP")
          ? 1
          : "",

      metaData: {
        justification: justify04,
      },
    },

    glueReturn && {
      id: "PPQ005",
      position:
        glueTubReturn.includes("INF") && glueTubReturn.includes("SUP")
          ? 3
          : glueTubReturn.includes("INF")
          ? 0
          : glueTubReturn.includes("SUP")
          ? 1
          : "",
      metaData: {
        justification: justify05,
      },
    },

    complementReturn && {
      id: "PPQ007",
      position: 0,
      description: `${JSON.stringify(compReturn.map((item: any) => item))}`,
      metaData: {
        justification: justify07,
      },
    },

    othersProceduresReturn && {
      id: "PPQ008",
      description: `${JSON.stringify(
        valueOthersReturn.map((item: any) => item)
      )}`,
      metaData: {
        justification: justify08,
      },
    },

    othersReferralsReturn && {
      id: "PPQ009",
      url: "",
      description: `${JSON.stringify(valueEncReturn.map((item: any) => item))}`,
      metaData: {
        justification: justify09,
      },
    },

    installMovableReturn && {
      id: "PPQ015",
      position:
        infMovableProcedureReturn && supMovableProcedureReturn
          ? 3
          : infMovableProcedureReturn && !supMovableProcedureReturn
          ? 0
          : 1,
      metaData: {
        justification: justify15,
      },
    },

    moldageSecondMovableReturn && {
      id: "PPQ016",
      position:
        infMovableProcedureReturn && supMovableProcedureReturn
          ? 3
          : infMovableProcedureReturn && !supMovableProcedureReturn
          ? 0
          : 1,
      metaData: {
        justification: justify16,
      },
    },
  ].filter(Boolean) as INextAppointment[];

  const anamnese = [
    ...selectedOptions.map((item) => ({
      id: item.identify,
      option: 1,
    })),
    ...answeredYes.map((item) => ({
      id: item.questionIdentifier,
      description: observations[item.questionText],
      option: 1,
    })),
    ...answeredNo.map((item) => ({
      id: item.questionIdentifier,
      option: 0,
    })),
  ];

  const schedules =
    bandaExists && !onlyBand
      ? [
          {
            idPac: patientObj.pac_id,
            idDent: user.data.dentist.id,
            date: `${formattedDateUTC()} ${selectedHour}`,
            interval: Number(selectedTime),
            procedure: selectedProceduresId,
          },
          {
            idPac: patientObj.pac_id,
            idDent: separatorId,
            date: `${formattedDateUTCBand()} ${selectedHourBand}`,
            interval: Number(selectedTimeBand),
            procedure: bandaId,
          },
        ]
      : onlyBand
      ? [
          {
            idPac: patientObj.pac_id,
            idDent: separatorId,
            date: `${formattedDateUTCBand()} ${selectedHourBand}`,
            interval: Number(selectedTimeBand),
            procedure: bandaId,
          },
        ]
      : [
          {
            idPac: patientObj.pac_id,
            idDent: user.data.dentist.id,
            date: `${formattedDateUTC()} ${selectedHour}`,
            interval: Number(selectedTime),
            procedure: selectedProceduresId,
          },
        ];

  const diagnosisArray = [
    diagnosisDescription
      ? {
          question: "Descrição",
          description: diagnosisDescription,
        }
      : "",
    btnCirurgy === "YES"
      ? {
          question: "Caso para Cirurgia Ortognática",
          description: "Sim",
        }
      : "",

    btnPatients === "YES" ||
      (btnOthers === "YES"
        ? {
            question: "Realizara a Cirurgia",
            description: "Sim",
          }
        : ""),

    brace !== 0
      ? {
          question: "Tipo de aparelho",
          description:
            brace === 1 ? "Aparelho Fixo" : brace === 2 && "Aparelho Móvel",
        }
      : "",

    selectedItems && selectedItems.length > 0
      ? {
          tratamentComplement: selectedItems.map((item) => `${item}`),
        }
      : "",
  ].filter(Boolean);

  const jsonAll = {
    ...(diagnosisArray &&
      diagnosisArray.length > 0 && { diagnosis: diagnosisArray }),
    procedures: [
      braceType.length > 0 && {
        question: "Colagem de aparatologia Ortodôntica",
        description: braceType.includes("LOWER") ? "Inferior" : "Superior",
        metaData: {
          wire: materialProcedure,
          type: numberProcedure,
          justification: "",
        },
      },

      painPrescriptionNeeded !== "NOT" && {
        question: "Precisa de receita para o caso de dor?",
        description: painPrescriptionNeeded === "YES" ? "Sim" : "Não",
        observation: painPrescriptionObservations,
      },

      informedBasicCare !== "NOT" && {
        question: "Informou paciente sobre os cuidados básicos com o aparelho?",
        description: informedBasicCare === "YES" ? "Sim" : "Não",
        observation: basicCareObservations,
      },

      deliveredTreatmentManual !== "NOT" && {
        question: "Entregou o manual do tratamento ortodôntico?",
        description: deliveredTreatmentManual === "YES" ? "Sim" : "Não",
        observation: treatmentManualObservations,
      },

      advisedWireAndWax !== "NOT" && {
        question:
          "Orientou o paciente a solicitar passa fio e cera na recepção?",
        description: advisedWireAndWax === "YES" ? "Sim" : "Não",
        observation: wireAndWaxObservations,
      },

      repOrRec.length > 0 && {
        question: "Reposição ou Recolagem de Braquete",
        description: `${repOrRec.includes("REC") ? "Recolagem" : ""} ${
          repOrRec.includes("REP") ? "Reposição" : ""
        } ${repOrRecBrace.includes("SUP") ? "Superior" : ""} ${
          repOrRecBrace.includes("INF") ? "Inferior" : ""
        }`.trim(),
      },
      additionalDevice &&
        additionalDevice.length > 0 && {
          question: "Aparelho complementar",
          description: additionalDevice.map((item: any) => item),
        },
      otherProceduresValue &&
        otherProceduresValue.length > 0 && {
          question: "Outros procedimentos",
          description: otherProceduresValue.map((item: any) => item),
        },

      otherProceduresValue[currentProcedureIndex] === "Mini Implante" && {
        question: "Mini Implante",
        realize: realize === "YES" ? "Sim" : "Não",
        justification: justification,
      },

      otherProceduresValue[currentProcedureIndex] ===
        "Controle de Contenção" && {
        question: "Controle de Contenção",
        realize: realizeContent === "YES" ? "Sim" : "Não",
        justification: justificationContent,
        region: informateRegionContent,
      },

      otherProceduresValue[currentProcedureIndex] ===
        "Remoção de Aparatologia Ortodôntica" && {
        question: "Remoção de Aparatologia Ortodôntica",
        realize: realizeAparat === "YES" ? "Sim" : "Não",
        justification: justificationAparat,
      },

      otherProceduresValue[currentProcedureIndex] === "Controle de Erupção" && {
        question: "Controle de Erupção",
        realize: realizeEruption === "YES" ? "Sim" : "Não",
        justification: justificationEruption,
        region: informateRegionEruption,
      },

      otherProceduresValue[currentProcedureIndex] ===
        "Instalação de elásticos intermaxilares" && {
        question: "Instalação de elásticos intermaxilares",
        realize: realizeIntermaxilary === "YES" ? "Sim" : "Não",
        justification: justificationIntermaxilary,
      },

      otherProceduresValue[currentProcedureIndex] === "Colagem de Botão" && {
        question: "Colagem de Botão",
        realize: realizeCollage === "YES" ? "Sim" : "Não",
        justification: justificationCollage,
      },

      otherProceduresValue[currentProcedureIndex] === "Recolagem Contenção" && {
        question: "Recolagem Contenção",
        realize: realizeRebonding === "YES" ? "Sim" : "Não",
        justification: justificationRebonding,
      },

      otherProceduresValue[currentProcedureIndex] === "Levante de Mordida" && {
        question: "Levante de Mordida",
        realize: realizeBite === "YES" ? "Sim" : "Não",
        justification: justificationBite,
      },

      otherProceduresValue[currentProcedureIndex] === "Alta" && {
        question: "Alta",
        realize: realizeRelease === "YES" ? "Sim" : "Não",
        justification: justificationRelease,
        finalDocumentation: route === "YES" ? "Sim" : "Não",
        justifyDocumentation: justifyRoute,
      },

      otherProceduresValue[currentProcedureIndex] === "Manutenção" && {
        question: "Manutenção",
        Fio: {
          description: "Trocar ou Manter Fio",
          type: braceTypeMaintenance === "LOWER" ? "Inferior" : "Superior",
          metaData: {
            wire: materialProcedureMaintenance,
            type: numberProcedureMaintenance,
            justification: othersProceduresFixedMaintenance,
          },
        },

        ReposicaoOuColagem: {
          description: "Reposição Ou Recolagem de Braquete",
          value: `${repOrRecProcedure === "REC" ? "Recolagem" : "Reposição"} ${
            repOrRecBraceProcedure === "SUP" ? "Superior" : "Inferior"
          }`,
        },

        ReceitaCasoDeDor: {
          description: "Precisa de receita para o caso de dor?",
          value: painPrescriptionNeededMaintenance,
        },

        CuidadosBasicosComAparelho: {
          description:
            "Informou paciente sobre os cuidados básicos com o aparelho?",
          value: informedBasicCareMaintenance,
          observation: basicCareObservationsMaintenance,
        },
      },

      proceduresLastAttendance[currentProcedureIndexReturn] ===
        "Mini Implante" && {
        question: "Mini Implante - Planejamento da Consulta Anterior",
        realize: realizeReturn === "YES" ? "Sim" : "Não",
        justification: justificationReturn,
      },

      proceduresLastAttendance[currentProcedureIndexReturn] ===
        "Controle de Contenção" && {
        question: "Controle de Contenção - Planejamento da Consulta Anterior",
        realize: realizeContentReturn === "YES" ? "Sim" : "Não",
        justification: justificationContentReturn,
        region: informateRegionContentReturn,
      },

      proceduresLastAttendance[currentProcedureIndexReturn] ===
        "Remoção de Aparatologia Ortodôntica" && {
        question:
          "Remoção de Aparatologia Ortodôntica - Planejamento da Consulta Anterior",
        realize: realizeAparatReturn === "YES" ? "Sim" : "Não",
        justification: justificationAparatReturn,
      },

      proceduresLastAttendance[currentProcedureIndexReturn] ===
        "Controle de Erupção" && {
        question: "Controle de Erupção - Planejamento da Consulta Anterior",
        realize: realizeEruptionReturn === "YES" ? "Sim" : "Não",
        justification: justificationEruptionReturn,
        region: informateRegionEruptionReturn,
      },

      proceduresLastAttendance[currentProcedureIndexReturn] ===
        "Instalação de elásticos intermaxilares" && {
        question:
          "Instalação de elásticos intermaxilares - Planejamento da Consulta Anterior",
        realize: realizeIntermaxilaryReturn === "YES" ? "Sim" : "Não",
        justification: justificationIntermaxilaryReturn,
      },

      proceduresLastAttendance[currentProcedureIndexReturn] ===
        "Colagem de Botão" && {
        question: "Colagem de Botão - Planejamento da Consulta Anterior",
        realize: realizeCollageReturn === "YES" ? "Sim" : "Não",
        justification: justificationCollageReturn,
      },

      proceduresLastAttendance[currentProcedureIndexReturn] ===
        "Recolagem Contenção" && {
        question: "Recolagem Contenção - Planejamento da Consulta Anterior",
        realize: realizeRebondingReturn === "YES" ? "Sim" : "Não",
        justification: justificationRebondingReturn,
      },

      proceduresLastAttendance[currentProcedureIndexReturn] ===
        "Levante de Mordida" && {
        question: "Levante de Mordida - Planejamento da Consulta Anterior",
        realize: realizeBiteReturn === "YES" ? "Sim" : "Não",
        justification: justificationBiteReturn,
      },

      proceduresLastAttendance[currentProcedureIndexReturn] === "Alta" && {
        question: "Alta - Planejamento da Consulta Anterior",
        realize: realizeReleaseReturn === "YES" ? "Sim" : "Não",
        justification: justificationReleaseReturn,
        finalDocumentation: routeReturn === "YES" ? "Sim" : "Não",
        justifyDocumentation: justifyRouteReturn,
      },

      proceduresLastAttendance[currentProcedureIndexReturn] ===
        "Manutenção" && {
        question: "Manutenção - Planejamento da Consulta Anterior",
        Fio: {
          description: "Trocar ou Manter Fio",
          type:
            braceTypeMaintenanceReturn === "LOWER" ? "Inferior" : "Superior",
          metaData: {
            wire: materialProcedureMaintenanceReturn,
            type: numberProcedureMaintenanceReturn,
            justification: othersProceduresFixedMaintenanceReturn,
          },
        },

        ReposicaoOuColagem: {
          description: "Reposição Ou Recolagem de Braquete",
          value: `${
            repOrRecProcedureReturn === "REC" ? "Recolagem" : "Reposição"
          } ${
            repOrRecBraceProcedureReturn === "SUP" ? "Superior" : "Inferior"
          }`,
        },

        ReceitaCasoDeDor: {
          description: "Precisa de receita para o caso de dor?",
          value: painPrescriptionNeededMaintenanceReturn,
        },

        CuidadosBasicosComAparelho: {
          description:
            "Informou paciente sobre os cuidados básicos com o aparelho?",
          value: informedBasicCareMaintenanceReturn,
          observation: basicCareObservationsMaintenanceReturn,
        },
      },
    ].filter(Boolean),
    ...(patientObj.aname_id > 0 &&
      nextAppointmentReturn &&
      nextAppointmentReturn.length > 0 && {
        planningPreviousConsultation: nextAppointmentReturn,
      }),
    planning: nextAppointment,
    schedule: schedules,
    ...(responses &&
      responses.length > 0 && {
        popup: responses.map((resp) => ({
          id: resp.id,
          description: resp.description,
        })),
      }),
  };

  const payload: ISendSchedule = {
    nextAppointment: nextAppointment,
    odont: odont,
    schedules: schedules,
    budget: repOrRec.includes("REP") ? 1 : 0,
    id: user.data.id,
    idPac: patientObj.pac_id,
    idRecord: patientObj.record_id,
    idSchedule: patientObj.id,
    idBudget: patientObj.budget,
    doc: jsonAll,
    signature: {
      pac: signaturePatient,
      dent: signatureDoctor,
    },
  };

  const payloadHigh: ISendSchedule = {
    nextAppointment: [],
    odont: [],
    schedules: [],
    budget: repOrRec.includes("REP") ? 1 : 0,
    id: user.data.id,
    idPac: patientObj.pac_id,
    idRecord: patientObj.record_id,
    idSchedule: patientObj.id,
    idBudget: patientObj.budget,
    doc: {
      procedures: [
        {
          question: "Alta",
          realize: "Sim",
          justification: justificationRelease,
          finalDocumentation: route === "YES" ? "Sim" : "Não",
          justifyDocumentation: justifyRoute,
        },
      ],
    },
    signature: {
      pac: signaturePatient,
      dent: signatureDoctor,
    },
  };

  const payloadDiagnosis: ISendSchedule = {
    nextAppointment: [],
    odont: [],
    schedules: [],
    budget: repOrRec.includes("REP") ? 1 : 0,
    id: user.data.id,
    idPac: patientObj.pac_id,
    idRecord: patientObj.record_id,
    idSchedule: patientObj.id,
    idBudget: patientObj.budget,
    doc: {
      diagnosis: diagnosisArray,
    },
    signature: {
      pac: signaturePatient,
      dent: signatureDoctor,
    },
  };

  const [openOrto, setOpenOrto] = React.useState<boolean>(false);

  const [textDialog, setTextDialog] = React.useState<string[]>([]);
  const navigateToHome = () => navigate("/home");
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(false);

  const closeDialog = () => {
    setOpenDialog(false);
    navigateToHome();
  };

  const sendConsult = async () => {
    if (!signaturePatient) {
      toast.error("Preencha a assinatura do paciente");
      return false;
    }
    if (!signatureDoctor) {
      toast.error("Preencha a assinatura do dentista");
      return false;
    }

    const payloadAnamnese: IAnamneseData = {
      idPac: patientObj.pac_id,
      id: user.data.id,
      data: anamnese,
    };

    if (patientObj.aname_id > 0 || noCirurgyDiagnosis) {
      setLoading(true);
      const { status, data } = await sendSchedule(
        noCirurgyDiagnosis
          ? payloadDiagnosis
          : receiveHigh
          ? payloadHigh
          : payload
      );

      if ([200, 201].includes(status)) {
        toast.success(data.message);

        if (
          !data.data.scheduled ||
          (Array.isArray(data.data.scheduled) &&
            data.data.scheduled.length === 0)
        ) {
          navigateToHome();
        } else {
          let scheduledMessages: string[];

          // Verifica se scheduled é um objeto
          if (
            typeof data.data.scheduled === "object" &&
            !Array.isArray(data.data.scheduled)
          ) {
            // Extrai as mensagens do objeto
            scheduledMessages = Object.values(data.data.scheduled);
          } else {
            // Caso seja um array, apenas usa como está
            scheduledMessages = data.data.scheduled;
          }

          // Verifica se scheduledMessages é um array de strings
          if (
            Array.isArray(scheduledMessages) &&
            scheduledMessages.length > 0
          ) {
            setTextDialog(scheduledMessages);
            setOpenDialog(true);
          } else {
            navigateToHome();
          }
        }
      } else {
        toast.error(data.message);
      }
    } else {
      setLoading(true);

      const { status: statusAnamnese, data: dataAnamnese } = await postAnamnese(
        payloadAnamnese
      );

      if ([200, 201].includes(statusAnamnese)) {
        const { status, data } = await sendSchedule(payload);

        if ([200, 201].includes(status)) {
          toast.success(data.message);

          if (
            !data.data.scheduled ||
            (Array.isArray(data.data.scheduled) &&
              data.data.scheduled.length === 0)
          ) {
            navigateToHome();
          } else {
            let scheduledMessages: string[];

            // Verifica se scheduled é um objeto
            if (
              typeof data.data.scheduled === "object" &&
              !Array.isArray(data.data.scheduled)
            ) {
              // Extrai as mensagens do objeto
              scheduledMessages = Object.values(data.data.scheduled);
            } else {
              // Caso seja um array, apenas usa como está
              scheduledMessages = data.data.scheduled;
            }

            // Verifica se scheduledMessages é um array de strings
            if (
              Array.isArray(scheduledMessages) &&
              scheduledMessages.length > 0
            ) {
              setTextDialog(scheduledMessages);
              setOpenDialog(true);
            } else {
              navigateToHome();
            }
          }
        } else {
          toast.error(data.message);
        }
      } else {
        toast.error(dataAnamnese.message);
      }
    }

    setLoading(false);
  };

  return (
    <>
      <Styled.Box className="printable">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {referralButtons.length > 0 ? (
            <Styled.BoxSaveOrto
              className="no-print"
              onClick={() => setOpenOrto(true)}
            >
              ORTOS
            </Styled.BoxSaveOrto>
          ) : (
            <Styled.BoxSaveOrtoFake></Styled.BoxSaveOrtoFake>
          )}

          <Styled.BoxSave onClick={handlePrint} className="no-print">
            Imprimir
          </Styled.BoxSave>
        </Box>

        <Modal open={openOrto} onClose={() => setOpenOrto(false)}>
          <Box
            sx={{
              backgroundColor: "white",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Typography textAlign="center" fontWeight="bold" fontSize="18px">
              Baixe os ORTOS selecionados
            </Typography>
            {referralButtons.map((button: any, index: any) => (
              <div
                key={index}
                style={{ marginBottom: "8px" }}
                className="no-print"
              >
                {button}
              </div>
            ))}
            <Button variant="outlined" onClick={() => setOpenOrto(false)}>
              Fechar
            </Button>
          </Box>
        </Modal>

        {noCirurgyDiagnosis ? (
          <Styled.BoxClinical>
            <Typography variant="h5">Diagnóstico</Typography>
            <Typography>{diagnosisDescription}</Typography>

            {btnCirurgy === "YES" ? (
              <Typography>Caso para cirurgia ortognática</Typography>
            ) : (
              ""
            )}
            {btnOrtognatica === "YES" ? (
              <Typography>
                Possível tratamento sem Cirurgia ortognática - ORTO41
              </Typography>
            ) : (
              ""
            )}
            {btnPatients === "YES" ? (
              <Typography>Paciente realizará a cirurgia</Typography>
            ) : (
              ""
            )}

            {btnPatients === "NO" && (
              <Typography>Paciente não realizará a cirurgia</Typography>
            )}

            {btnOthers === "YES" ? (
              <Typography>Paciente realizará a cirurgia</Typography>
            ) : (
              ""
            )}

            <Typography>{brace === 1 ? "Aparelho Fixo" : ""}</Typography>
            <Typography>
              {brace === 2 ? "Aparelho Complementar/Móvel" : ""}
            </Typography>
            {btnOthers === "YES" && (
              <Box sx={{ backgroundColor: "#f7f8fa", padding: "10px" }}>
                <Typography sx={{ fontWeight: "bold !important" }}>
                  Tratamento Complementar - ORTO42
                </Typography>
                {selectedItems.map((item, index) => (
                  <Typography key={index}>{item}</Typography>
                ))}
              </Box>
            )}

            {obsDiagnosis && obsDiagnosis.length > 1 ? (
              <Typography>
                Observações: <br /> {obsDiagnosis}
              </Typography>
            ) : (
              ""
            )}
          </Styled.BoxClinical>
        ) : receiveHigh ? (
          <Styled.BoxClinical>
            <Typography variant="h5">Paciente recebeu alta</Typography>
          </Styled.BoxClinical>
        ) : (
          <>
            {patientObj.aname_id > 0 ? (
              ""
            ) : (
              <>
                <Styled.BoxClinical className="boxValue">
                  <Typography variant="h5">Diagnóstico</Typography>
                  <hr />
                  <Box>
                    <Typography>{diagnosisDescription}</Typography>

                    {btnCirurgy === "YES" ? (
                      <Typography>Caso para cirurgia ortognática</Typography>
                    ) : (
                      ""
                    )}
                    {btnOrtognatica === "YES" ? (
                      <Typography>
                        Possível tratamento sem Cirurgia ortognática - ORTO41
                      </Typography>
                    ) : (
                      ""
                    )}
                    {btnPatients === "YES" ? (
                      <Typography>Paciente realizará a cirurgia</Typography>
                    ) : (
                      ""
                    )}

                    {btnOthers === "YES" ? (
                      <Typography>Paciente realizará a cirurgia</Typography>
                    ) : (
                      ""
                    )}

                    <Typography>
                      {brace === 1 ? "Aparelho Fixo" : ""}
                    </Typography>
                    <Typography>
                      {brace === 2 ? "Aparelho Complementar/Móvel" : ""}
                    </Typography>
                    {btnOthers === "YES" && (
                      <Box sx={{ backgroundColor: "#f7f8fa", padding: "10px" }}>
                        <Typography sx={{ fontWeight: "bold !important" }}>
                          Tratamento Complementar - ORTO42
                        </Typography>
                        {selectedItems.map((item, index) => (
                          <Typography key={index}>{item}</Typography>
                        ))}
                      </Box>
                    )}

                    {obsDiagnosis && obsDiagnosis.length > 1 ? (
                      <Typography>
                        Observações: <br /> {obsDiagnosis}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                </Styled.BoxClinical>

                <Styled.BoxClinical className="boxValue">
                  <Typography variant="h5">Anamnese</Typography>

                  <hr />
                  {selectedOptions.map((option, index) => (
                    <Typography key={index}>{option.label}</Typography>
                  ))}
                  {answeredYes && answeredYes.length > 0 ? (
                    <Box
                      sx={{
                        backgroundColor: "#f7f8fa",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        padding: "10px",
                        margin: "10px 0",
                      }}
                    >
                      {answeredYes.map((question) => (
                        <Box key={question.questionText}>
                          <Typography sx={{ fontWeight: "bold !important" }}>
                            {question.questionText}
                          </Typography>
                          {observations[question.questionText] && (
                            <>
                              <Typography variant="body2">Sim</Typography>
                              <Typography variant="body2">
                                Observação:{" "}
                                {observations[question.questionText]}
                              </Typography>
                            </>
                          )}
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    ""
                  )}
                  {answeredNo && answeredNo.length > 0 ? (
                    <Box
                      sx={{
                        backgroundColor: "#f7f8fa",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        padding: "10px",
                      }}
                    >
                      {answeredNo.map((question) => (
                        <Box key={question.questionText}>
                          <Typography sx={{ fontWeight: "bold !important" }}>
                            {question.questionText}
                          </Typography>
                          <Typography variant="body2">Não</Typography>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    ""
                  )}
                </Styled.BoxClinical>
              </>
            )}

            <Styled.BoxClinical className="boxValue">
              <Typography variant="h5">Procedimentos Realizados</Typography>

              <hr />

              <Box>
                {installMovableReturn && (
                  <>
                    {supMovableProcedureReturn === true &&
                    infMovableProcedureReturn === false ? (
                      <Typography>
                        Instalação de Aparelho Móvel Superior
                      </Typography>
                    ) : supMovableProcedureReturn === false &&
                      infMovableProcedureReturn === true ? (
                      <Typography>
                        Instalação de Aparelho Móvel Inferior
                      </Typography>
                    ) : (
                      <Typography>
                        Instalação de Aparelho Móvel Superior e Inferior
                      </Typography>
                    )}
                  </>
                )}

                {moldageSecondMovableReturn && (
                  <>
                    {supMovableProcedureReturn === true &&
                    infMovableProcedureReturn === false ? (
                      <Typography>Realizar moldagem Superior</Typography>
                    ) : supMovableProcedureReturn === false &&
                      infMovableProcedureReturn === true ? (
                      <Typography>Realizar moldagem Inferior</Typography>
                    ) : (
                      <Typography>
                        Realizar moldagem Superior e Inferior
                      </Typography>
                    )}
                  </>
                )}

                {braceType.length > 0 && (
                  <>
                    {braceType.includes("UPPER") && (
                      <>
                        <Typography>
                          Colagem de Aparatologia Ortodôntica Superior
                        </Typography>

                        {materialProcedure ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Material Superior
                            </Typography>
                            <Typography>{materialProcedure}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {numberProcedure ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Número do aparelho Superior
                            </Typography>
                            <Typography>{numberProcedure}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {othersProceduresFixed ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Outros Superior
                            </Typography>
                            <Typography>{othersProceduresFixed}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                      </>
                    )}

                    {braceType.includes("LOWER") && (
                      <>
                        <Typography>
                          Colagem de Aparatologia Ortodôntica Inferior
                        </Typography>

                        {materialProceduresInf ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Material Inferior
                            </Typography>
                            <Typography>{materialProceduresInf}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {numberProceduresInf ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Número do aparelho Inferior
                            </Typography>
                            <Typography>{numberProceduresInf}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {othersProceduresInf ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Outros Inferior
                            </Typography>
                            <Typography>{othersProceduresInf}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </>
                )}

                {wireProcedures && (
                  <Box
                    sx={{
                      backgroundColor: "#f7f8fa",
                      padding: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Trocar fio
                    </Typography>
                    {changeWireProcedures.length > 0 ? (
                      <Typography>
                        {changeWireProcedures.includes("INF") &&
                        changeWireProcedures.includes("SUP")
                          ? "Superior e Inferior"
                          : changeWireProcedures.includes("INF")
                          ? "Inferior"
                          : changeWireProcedures.includes("SUP")
                          ? "Superior"
                          : ""}
                      </Typography>
                    ) : (
                      ""
                    )}

                    {changeWireProcedures.includes("SUP") && (
                      <>
                        {materialChangeProcedures ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Material Superior
                            </Typography>
                            <Typography>{materialChangeProcedures}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {numberChangeProcedures ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Número do aparelho Superior
                            </Typography>
                            <Typography>{numberChangeProcedures}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {othersChangeProcedures ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Outros Superior
                            </Typography>
                            <Typography>{othersChangeProcedures}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                      </>
                    )}

                    {changeWireProcedures.includes("INF") && (
                      <>
                        {materialChangeProceduresInf ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Material Inferior
                            </Typography>
                            <Typography>
                              {materialChangeProceduresInf}
                            </Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {numberChangeProceduresInf ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Número do aparelho Inferior
                            </Typography>
                            <Typography>{numberChangeProceduresInf}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {othersChangeProceduresInf ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Outros Inferior
                            </Typography>
                            <Typography>{othersChangeProceduresInf}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </Box>
                )}

                {keepThreadProcedures && (
                  <Box sx={{ backgroundColor: "#f7f8fa", padding: "10px" }}>
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Manter fio
                    </Typography>
                    {keepProcedures.length > 0 ? (
                      <Typography>
                        {keepProcedures.includes("INF") &&
                        keepProcedures.includes("SUP")
                          ? "Superior e Inferior"
                          : keepProcedures.includes("INF")
                          ? "Inferior"
                          : keepProcedures.includes("SUP")
                          ? "Superior"
                          : ""}
                      </Typography>
                    ) : (
                      ""
                    )}

                    {ativationProcedures ? (
                      <Typography>
                        {ativationProcedures === "yes"
                          ? "Com ativação"
                          : "Sem ativação"}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                )}

                {cimentProcedures && (
                  <>
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Cimentação de Banda
                    </Typography>

                    <Typography>
                      {cimentBandProcedures.includes("INF") &&
                      cimentBandProcedures.includes("SUP")
                        ? "Superior e Inferior"
                        : cimentBandProcedures.includes("INF")
                        ? "Inferior"
                        : cimentBandProcedures.includes("SUP")
                        ? "Superior"
                        : ""}
                    </Typography>
                  </>
                )}

                {glueProcedures && (
                  <>
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Colagem do tubo
                    </Typography>

                    <Typography>
                      {glueTubProcedures.includes("INF") &&
                      glueTubProcedures.includes("SUP")
                        ? "Superior e Inferior"
                        : glueTubProcedures.includes("INF")
                        ? "Inferior"
                        : glueTubProcedures.includes("SUP")
                        ? "Superior"
                        : ""}
                    </Typography>
                  </>
                )}

                {needsBand === "YES" ? (
                  <Typography>Vai precisar de banda</Typography>
                ) : (
                  ""
                )}
                {performMolding === "YES" ? (
                  <Typography>Realizou a moldagem</Typography>
                ) : (
                  ""
                )}
                {performMolding === "NO" ? (
                  <>
                    <Typography>Não realizou a moldagem</Typography>
                    <Typography>
                      Motivo: <br /> {moldingJustification}
                    </Typography>
                  </>
                ) : (
                  ""
                )}
                {needsSeparator === "YES" ? (
                  <Typography>Vai precisar de separador</Typography>
                ) : (
                  ""
                )}

                {painPrescriptionNeeded === "YES" ? (
                  <Typography>Precisa de receita para dor - ORTO22N</Typography>
                ) : (
                  ""
                )}

                {painPrescriptionNeeded === "NO" ? (
                  <Typography>Não precisa de receita para dor</Typography>
                ) : (
                  ""
                )}

                {informedBasicCare === "YES" ? (
                  <Typography>
                    Informou o paciente sobre os cuidados básicos com o aparelho
                  </Typography>
                ) : (
                  ""
                )}

                {deliveredTreatmentManual === "YES" ? (
                  <Typography>Entregou o manual do tratamento</Typography>
                ) : (
                  ""
                )}

                {advisedWireAndWax === "YES" ? (
                  <Typography>
                    Orientou o paciente a solicitar fio e cera na recepção
                  </Typography>
                ) : (
                  ""
                )}

                {hasComplement &&
                additionalDevice &&
                additionalDevice.length > 1 ? (
                  <>
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Aparelho complementar
                    </Typography>
                    {additionalDevice.map((item: any, index: any) => (
                      <Typography key={index}>{item.label}</Typography>
                    ))}
                  </>
                ) : (
                  <Typography></Typography>
                )}

                {hasOtherProcedures && (
                  <>
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Outros Procedimentos
                    </Typography>
                    {otherProceduresValue
                      .map((item: any) => `${item},`)
                      .map((item: any, index: any) => (
                        <Typography key={index}>{item.label}</Typography>
                      ))}
                  </>
                )}

                {hasOtherReferrals && (
                  <>
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Encaminhamentos
                    </Typography>
                    {referralValue.map((item: any, index: any) => (
                      <Typography key={index}>{item.label}</Typography>
                    ))}
                  </>
                )}

                {aparateReturn && (
                  <Box
                    sx={{
                      backgroundColor: "#f7f8fa",
                      padding: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Colagem da Aparatologia Ortodôntica
                    </Typography>

                    {aparateBraceReturn.length > 0 ? (
                      <Typography>
                        {aparateBraceReturn.includes("INF") &&
                        aparateBraceReturn.includes("SUP")
                          ? "Superior e Inferior"
                          : aparateBraceReturn.includes("INF")
                          ? "Inferior"
                          : aparateBraceReturn.includes("SUP")
                          ? "Superior"
                          : ""}
                      </Typography>
                    ) : (
                      ""
                    )}

                    {aparateBraceReturn.includes("SUP") && (
                      <>
                        {materialReturn ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Material Superior
                            </Typography>
                            <Typography>{materialReturn}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {numberReturn ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Número do aparelho Superior
                            </Typography>
                            <Typography>{numberReturn}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {othersReturn ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Outros Superior
                            </Typography>
                            <Typography>{othersReturn}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                      </>
                    )}

                    {aparateBraceReturn.includes("INF") && (
                      <>
                        {materialReturnInf ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Material Inferior
                            </Typography>
                            <Typography>{materialReturnInf}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {numberReturnInf ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Número do aparelho Inferior
                            </Typography>
                            <Typography>{numberReturnInf}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {othersReturnInf ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Outros Inferior
                            </Typography>
                            <Typography>{othersReturnInf}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </Box>
                )}

                {wireReturn && (
                  <Box
                    sx={{
                      backgroundColor: "#f7f8fa",
                      padding: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Trocar fio
                    </Typography>
                    {changeWireReturn.length > 0 ? (
                      <Typography>
                        {changeWireReturn.includes("INF") &&
                        changeWireReturn.includes("SUP")
                          ? "Superior e Inferior"
                          : changeWireReturn.includes("INF")
                          ? "Inferior"
                          : "Superior"}
                      </Typography>
                    ) : (
                      ""
                    )}

                    {changeWireReturn.includes("SUP") && (
                      <>
                        {materialChangeReturn ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Material Superior
                            </Typography>
                            <Typography>{materialChangeReturn}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {numberChangeReturn ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Número do aparelho Superior
                            </Typography>
                            <Typography>{numberChangeReturn}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {othersChangeReturn ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Outros Superior
                            </Typography>
                            <Typography>{othersChangeReturn}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                      </>
                    )}

                    {changeWireReturn.includes("INF") && (
                      <>
                        {materialChangeReturnInf ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Material Superior
                            </Typography>
                            <Typography>{materialChangeReturnInf}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {numberChangeReturnInf ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Número do aparelho Superior
                            </Typography>
                            <Typography>{numberChangeReturnInf}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}

                        {othersChangeReturnInf ? (
                          <Box>
                            <Typography sx={{ fontWeight: "bold !important" }}>
                              Outros Superior
                            </Typography>
                            <Typography>{othersChangeReturnInf}</Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </Box>
                )}

                {keepThreadReturn && (
                  <Box sx={{ backgroundColor: "#f7f8fa", padding: "10px" }}>
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Manter fio
                    </Typography>
                    {keepReturn.length > 0 ? (
                      <Typography>
                        {keepReturn.includes("INF") &&
                        keepReturn.includes("SUP")
                          ? "Superior e Inferior"
                          : keepReturn.includes("INF")
                          ? "Inferior"
                          : "Superior"}
                      </Typography>
                    ) : (
                      ""
                    )}

                    {ativationReturn ? (
                      <Typography>
                        {ativationReturn === "yes"
                          ? "Com ativação"
                          : "Sem ativação"}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                )}

                {cimentReturn && (
                  <>
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Cimentação de Banda
                    </Typography>
                    <Typography>
                      {cimentBandReturn.includes("INF") &&
                      cimentBandReturn.includes("SUP")
                        ? "Superior e Inferior"
                        : cimentBandReturn.includes("INF")
                        ? "Inferior"
                        : cimentBandReturn.includes("SUP")
                        ? "Superior"
                        : ""}
                    </Typography>{" "}
                  </>
                )}

                {glueReturn && (
                  <>
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Colagem do tubo
                    </Typography>

                    <Typography>
                      {glueTubReturn.includes("INF") &&
                      glueTubReturn.includes("SUP")
                        ? "Superior e Inferior"
                        : glueTubReturn.includes("INF")
                        ? "Inferior"
                        : glueTubReturn.includes("SUP")
                        ? "Superior"
                        : ""}
                    </Typography>
                  </>
                )}

                {rep && (
                  <>
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Reposição ou Recolagem de Braquete
                    </Typography>

                    <Typography>
                      {repOrRec.includes("REP") && repOrRec.includes("REC")
                        ? "Reposição e Colagem"
                        : repOrRec.includes("REP")
                        ? "Reposição"
                        : repOrRec.includes("REC")
                        ? "Colagem"
                        : "Nenhuma"}
                    </Typography>

                    <Typography>
                      {repOrRecBrace.includes("SUP") &&
                      repOrRecBrace.includes("INF")
                        ? "Superior e Inferior"
                        : repOrRecBrace.includes("SUP")
                        ? "Superior"
                        : repOrRecBrace.includes("INF")
                        ? "Inferior"
                        : "Nenhum"}
                    </Typography>
                  </>
                )}

                {complementReturn && (
                  <>
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Aparelho Complementar
                    </Typography>

                    {compReturn &&
                      compReturn.length > 0 &&
                      compReturn.map((item: any, index: any) => (
                        <Typography key={index}>{item.label}</Typography>
                      ))}
                  </>
                )}

                {othersProceduresReturn && (
                  <>
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Outros procedimentos
                    </Typography>
                    {valueOthersReturn.map((item: any, index: any) => (
                      <Typography key={index}>{item.label}</Typography>
                    ))}
                  </>
                )}

                {othersReferralsReturn && (
                  <>
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Encaminhamentos
                    </Typography>
                    {valueEncReturn.map((item: any, index: any) => (
                      <Typography key={index}>{item.label}</Typography>
                    ))}
                  </>
                )}

                {responses.length > 0 &&
                  responses.map((item, index) => (
                    <Typography key={index}>
                      <b>{item.message}:</b> {item.description}
                    </Typography>
                  ))}
              </Box>
            </Styled.BoxClinical>

            <Styled.BoxClinical className="boxValue">
              <Typography variant="h5">
                Planejamento da Próxima consulta
              </Typography>

              <hr />

              {braceMovel &&
                (supMovable === true && infMovable === false ? (
                  <Typography>Instalação de Aparelho Móvel Superior</Typography>
                ) : supMovable === false && infMovable === true ? (
                  <Typography>Instalação de Aparelho Móvel Inferior</Typography>
                ) : (
                  <Typography>
                    Instalação de Aparelho Móvel Superior e Inferior
                  </Typography>
                ))}

              {relizeMoldage &&
                (supMovable === true && infMovable === false ? (
                  <Typography>Realizar Moldagem Superior</Typography>
                ) : supMovable === false && infMovable === true ? (
                  <Typography>Realizar Moldagem Inferior</Typography>
                ) : (
                  <Typography>Realizar Moldagem Superior e Inferior</Typography>
                ))}

              {aparate && (
                <Box
                  sx={{
                    backgroundColor: "#f7f8fa",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold !important" }}>
                    Colagem da Aparatologia Ortodôntica
                  </Typography>

                  {aparateBrace.includes("INF") ||
                  aparateBrace.includes("SUP") ? (
                    <Typography>
                      {aparateBrace.includes("INF") &&
                      aparateBrace.includes("SUP")
                        ? "Superior e Inferior"
                        : aparateBrace.includes("INF")
                        ? "Inferior"
                        : "Superior"}
                    </Typography>
                  ) : (
                    ""
                  )}

                  {aparateBrace.includes("SUP") && (
                    <>
                      {materialCola ? (
                        <Box>
                          <Typography sx={{ fontWeight: "bold !important" }}>
                            Material Superior
                          </Typography>
                          <Typography>{materialCola}</Typography>
                        </Box>
                      ) : (
                        ""
                      )}

                      {numberCola ? (
                        <Box>
                          <Typography sx={{ fontWeight: "bold !important" }}>
                            Número do aparelho Superior
                          </Typography>
                          <Typography>{numberCola}</Typography>
                        </Box>
                      ) : (
                        ""
                      )}

                      {othersCola ? (
                        <Box>
                          <Typography sx={{ fontWeight: "bold !important" }}>
                            Outros Superior
                          </Typography>
                          <Typography>{othersCola}</Typography>
                        </Box>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  {aparateBrace.includes("INF") && (
                    <>
                      {materialColaInf ? (
                        <Box>
                          <Typography sx={{ fontWeight: "bold !important" }}>
                            Material Inferior
                          </Typography>
                          <Typography>{materialColaInf}</Typography>
                        </Box>
                      ) : (
                        ""
                      )}

                      {numberColaInf ? (
                        <Box>
                          <Typography sx={{ fontWeight: "bold !important" }}>
                            Número do aparelho Inferior
                          </Typography>
                          <Typography>{numberColaInf}</Typography>
                        </Box>
                      ) : (
                        ""
                      )}

                      {othersColaInf ? (
                        <Box>
                          <Typography sx={{ fontWeight: "bold !important" }}>
                            Outros Inferior
                          </Typography>
                          <Typography>{othersColaInf}</Typography>
                        </Box>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </Box>
              )}

              {wire && (
                <Box
                  sx={{
                    backgroundColor: "#f7f8fa",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold !important" }}>
                    Trocar fio
                  </Typography>
                  {changeWire.length > 0 ? (
                    <Typography>
                      {changeWire.includes("INF") && changeWire.includes("SUP")
                        ? "Superior e Inferior"
                        : changeWire.includes("INF")
                        ? "Inferior"
                        : "Superior"}
                    </Typography>
                  ) : (
                    ""
                  )}

                  {changeWire.includes("SUP") && (
                    <>
                      {materialChange ? (
                        <Box>
                          <Typography sx={{ fontWeight: "bold !important" }}>
                            Material Superior
                          </Typography>
                          <Typography>{materialChange}</Typography>
                        </Box>
                      ) : (
                        ""
                      )}

                      {numberChange ? (
                        <Box>
                          <Typography sx={{ fontWeight: "bold !important" }}>
                            Número do aparelho Superior
                          </Typography>
                          <Typography>{numberChange}</Typography>
                        </Box>
                      ) : (
                        ""
                      )}

                      {othersChange ? (
                        <Box>
                          <Typography sx={{ fontWeight: "bold !important" }}>
                            Outros Superior
                          </Typography>
                          <Typography>{othersChange}</Typography>
                        </Box>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  {changeWire.includes("INF") && (
                    <>
                      {materialChangeInf ? (
                        <Box>
                          <Typography sx={{ fontWeight: "bold !important" }}>
                            Material Inferior
                          </Typography>
                          <Typography>{materialChangeInf}</Typography>
                        </Box>
                      ) : (
                        ""
                      )}

                      {numberChangeInf ? (
                        <Box>
                          <Typography sx={{ fontWeight: "bold !important" }}>
                            Número do aparelho Inferior
                          </Typography>
                          <Typography>{numberChangeInf}</Typography>
                        </Box>
                      ) : (
                        ""
                      )}

                      {othersChangeInf ? (
                        <Box>
                          <Typography sx={{ fontWeight: "bold !important" }}>
                            Outros Inferior
                          </Typography>
                          <Typography>{othersChangeInf}</Typography>
                        </Box>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </Box>
              )}

              {keepThread && (
                <Box sx={{ backgroundColor: "#f7f8fa", padding: "10px" }}>
                  <Typography sx={{ fontWeight: "bold !important" }}>
                    Manter fio
                  </Typography>
                  {keep.length > 0 ? (
                    <Typography>
                      {keep.includes("INF") && keep.includes("SUP")
                        ? "Superior e Inferior"
                        : keep.includes("INF")
                        ? "Inferior"
                        : "Superior"}
                    </Typography>
                  ) : (
                    ""
                  )}

                  {ativation ? (
                    <Typography>
                      {ativation === "yes" ? "Com ativação" : "Sem ativação"}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
              )}

              {ciment && (
                <>
                  <Typography sx={{ fontWeight: "bold !important" }}>
                    Cimentação de Banda
                  </Typography>

                  <Typography>
                    {cimentBand.includes("SUP") && cimentBand.includes("INF")
                      ? "Superior e Inferior"
                      : cimentBand.includes("SUP")
                      ? "Superior"
                      : cimentBand.includes("INF")
                      ? "Inferior"
                      : ""}
                  </Typography>
                </>
              )}

              {glue && (
                <>
                  <Typography sx={{ fontWeight: "bold !important" }}>
                    Colagem do tubo
                  </Typography>

                  <Typography>
                    {glueTube.includes("SUP") && glueTube.includes("INF")
                      ? "Superior e Inferior"
                      : glueTube.includes("SUP")
                      ? "Superior"
                      : glueTube.includes("INF")
                      ? "Inferior"
                      : ""}
                  </Typography>
                </>
              )}

              {complement && (
                <>
                  <Typography sx={{ fontWeight: "bold !important" }}>
                    Aparelho Complementar
                  </Typography>

                  {comp &&
                    comp.length > 0 &&
                    comp.map((item: any, index: any) => (
                      <Typography key={index}>{item.label}</Typography>
                    ))}
                </>
              )}

              {othersProcedures && (
                <>
                  <Typography sx={{ fontWeight: "bold !important" }}>
                    Outros procedimentos
                  </Typography>
                  {valueOthers.map((item: any, index: any) => (
                    <Typography key={index}>{item.label}</Typography>
                  ))}
                </>
              )}

              {othersReferrals && (
                <>
                  <Typography sx={{ fontWeight: "bold !important" }}>
                    Encaminhamentos
                  </Typography>
                  {valueEnc.map((item: any, index: any) => (
                    <Typography key={index}>{item.label}</Typography>
                  ))}
                </>
              )}
            </Styled.BoxClinical>

            <Styled.BoxClinical className="boxValue">
              {onlyBand ? (
                <>
                  <Typography variant="h5" sx={{ marginTop: "15px" }}>
                    Agendamento da Banda
                  </Typography>
                  <hr />
                  <Box>
                    <Typography>
                      <b>Data:</b> {formattedDateBand}
                    </Typography>
                    <Typography>
                      <b>Tempo de Consulta:</b>{" "}
                      {selectedTimeBand === "60"
                        ? "1 hora"
                        : `${selectedTimeBand} Minutos`}
                    </Typography>
                    <Typography>
                      <b>Horário Marcado:</b> {selectedHourBand}
                    </Typography>
                  </Box>
                </>
              ) : bandaExists && !onlyBand ? (
                <>
                  <Typography variant="h5">
                    Agendamento - {adjustmentAcentuation(mostImportantType)}
                  </Typography>
                  <hr />
                  <Box>
                    <Typography>
                      <b>Data:</b> {formattedDate}
                    </Typography>
                    <Typography>
                      <b>Tempo de Consulta:</b>{" "}
                      {selectedTime === "60"
                        ? "1 hora"
                        : `${selectedTime} Minutos`}
                    </Typography>
                    <Typography>
                      <b>Horário Marcado:</b> {selectedHour}
                    </Typography>
                  </Box>

                  <Typography variant="h5" sx={{ marginTop: "15px" }}>
                    Agendamento - Separador
                  </Typography>
                  <hr />
                  <Box>
                    <Typography>
                      <b>Data:</b> {formattedDateBand}
                    </Typography>
                    <Typography>
                      <b>Tempo de Consulta:</b>{" "}
                      {selectedTimeBand === "60"
                        ? "1 hora"
                        : `${selectedTimeBand} Minutos`}
                    </Typography>
                    <Typography>
                      <b>Horário Marcado:</b> {selectedHourBand}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Typography variant="h5">
                    Agendamento - {adjustmentAcentuation(mostImportantType)}
                  </Typography>
                  <hr />

                  <Box>
                    <Typography>
                      <b>Data:</b> {formattedDate}
                    </Typography>
                    <Typography>
                      <b>Tempo de Consulta:</b>{" "}
                      {selectedTime === "60"
                        ? "1 hora"
                        : `${selectedTime} Minutos`}
                    </Typography>
                    <Typography>
                      <b>Horário Marcado:</b> {selectedHour}
                    </Typography>
                  </Box>
                </>
              )}
            </Styled.BoxClinical>
          </>
        )}

        <Styled.BoxClinicalSignature className="boxValue">
          <Typography
            variant="h5"
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            Assinatura do Paciente{" "}
            <Button onClick={clearSignaturePatient} className="no-print">
              Limpar
            </Button>
          </Typography>
          <div className="boxSignature">
            <SignatureCanvas
              onEnd={saveSignaturePatient}
              ref={sigCanvasPatient}
              penColor="black"
              canvasProps={{ width: 670, height: 200, className: "sigCanvas" }}
            />
          </div>
        </Styled.BoxClinicalSignature>

        <Styled.BoxClinicalSignature className="boxValue">
          <Typography
            variant="h5"
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            Assinatura do Dentista{" "}
            <Button onClick={clearSignatureDentits} className="no-print">
              Limpar
            </Button>
          </Typography>
          <div className="boxSignature">
            <SignatureCanvas
              onEnd={saveSignatureDentist}
              ref={sigCanvasDentist}
              penColor="black"
              canvasProps={{ width: 670, height: 200, className: "sigCanvas" }}
            />
          </div>
        </Styled.BoxClinicalSignature>

        {isBtnFixedVisible ? (
          <Styled.BtnFixed>
            <Button
              fullWidth
              onClick={() =>
                setStep(noCirurgyDiagnosis ? 1 : receiveHigh ? 3 : 5)
              }
              variant="outlined"
            >
              Voltar
            </Button>
            <Button fullWidth onClick={() => sendConsult()} disabled={loading}>
              Finalizar Consulta
            </Button>
          </Styled.BtnFixed>
        ) : (
          ""
        )}
      </Styled.Box>

      <AlertDialog
        text={textDialog}
        open={openDialog}
        onClose={() => closeDialog()}
      />
    </>
  );
};

export { ClinicalRecord };
