import React from "react";

import * as Styled from "./styles";

import { ReactComponent as Logo } from "shared/assets/svg/logoWhite.svg";
//import { ReactComponent as Search } from "shared/assets/svg/search.svg";
//import { ReactComponent as Mic } from "shared/assets/svg/mic.svg";
import { MdExitToApp } from "react-icons/md";

//import { RxHamburgerMenu } from "react-icons/rx";
import {
  Container,
  /*InputAdornment*/ Modal /*TextField*/,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { ListMenu } from "shared/mock";
import { useNavigate } from "react-router-dom";
//import { useAuth } from "shared/providers/auth";

const HeaderApp: React.FC = () => {
  const navigate = useNavigate();
  //const { setSearchPatients, searchPatients } = useAuth();
  const [open, setOpen] = React.useState<boolean>(false);
  //const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  /*const handleVoiceInput = () => {
    const SpeechRecognition =
      (window as any).SpeechRecognition ||
      (window as any).webkitSpeechRecognition;
    if (!SpeechRecognition) {
      alert("Este navegador não suporta reconhecimento de voz.");
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.lang = "pt-BR";
    recognition.interimResults = false;

    recognition.onresult = (event: any) => {
      const transcript = event.results[0][0].transcript;
      setSearchPatients(transcript);
    };

    recognition.onerror = (event: any) => {
      console.error("Erro no reconhecimento de voz:", event.error);
    };

    recognition.start();
  };
*/
  return (
    <>
      <Styled.Header>
        <Container maxWidth="xl">
          <Styled.FirstLine>
            {/* 
            <Styled.Menu onClick={handleOpen}>
              <RxHamburgerMenu size={30} />
            </Styled.Menu>
            */}
            <Styled.FakeMenu>
              <div></div>
            </Styled.FakeMenu>
            <Styled.Logo>
              <Logo />
            </Styled.Logo>
            <Styled.FakeBox onClick={() => navigate("/exit")}>
              <MdExitToApp size={30} />
            </Styled.FakeBox>
          </Styled.FirstLine>

          {/* 
          <Styled.SecondLine>
            <TextField
              value={searchPatients}
              placeholder="Buscar paciente"
              onChange={(e) => setSearchPatients(e.target.value)}
              sx={{
                "& fieldset": { border: "none" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Mic onClick={handleVoiceInput} />
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Styled.SecondLine>
          */}
        </Container>
      </Styled.Header>
      <Modal open={open} onClose={handleClose}>
        <Styled.ModalBox>
          <Container maxWidth="xl">
            <Styled.FirstLineModal>
              <Styled.Menu onClick={handleClose}>
                <IoMdClose size={30} />
              </Styled.Menu>
              <Styled.Logo>
                <Logo />
              </Styled.Logo>
              <Styled.FakeBox>
                <div style={{ width: "30px", height: "34px" }}></div>
              </Styled.FakeBox>
            </Styled.FirstLineModal>
            <Styled.List>
              {ListMenu.map((item, index) => (
                <React.Fragment key={index}>
                  {item.blank ? (
                    <a href={item.href} target="_blank" rel="noreferrer">
                      {item.text}
                    </a>
                  ) : (
                    <a href={item.href}>{item.text}</a>
                  )}
                </React.Fragment>
              ))}
            </Styled.List>
          </Container>
        </Styled.ModalBox>
      </Modal>
    </>
  );
};

export { HeaderApp };
