import React from "react";

import * as Styled from "./styles";

import { ReactComponent as ArrowIcon } from "shared/assets/svg/arrow-left.svg";
import { phrases } from "shared/mock";

const AboutPage: React.FC = () => {
  return (
    <>
      <Styled.BgImage>
        <span onClick={() => window.history.back()}>
          <ArrowIcon />
        </span>
      </Styled.BgImage>
      <Styled.Box>
        <Styled.Title>
          Somos a OrthoDontic, a maior rede de franquias de ortodontia do
          Brasil!
        </Styled.Title>
        {phrases.map((item, index) => (
          <Styled.Phrase key={index}>{item}</Styled.Phrase>
        ))}
      </Styled.Box>
    </>
  );
};

export { AboutPage };
