import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;

  form {
    width: 100%;
  }
`;

export const More = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-top: 50px;

  a {
    color: #abb0ba;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
  }
`;
