export const ROUTER = {
  ONBOARDING: "/",
  LOGIN: "/login",
  RECOVERY: "/recovery",
  HOME: "/home",
  ABOUT: "/about",
  EXIT: "/exit",
  FIRST_CONSULT: "/first-consult",
  CLOSE_CONSULT: "/close-consult",
  DOCUMENTATION: "/documentation/:idPacient",
  AVALIATION: "/avaliation",
  MAINTENANCE: "/maintenance",
  CLINICAL_RECORD_USER: "/clinical-record-user/:idPacient",
  ODONTOGRAMA_USER: "/odontograma-user/:idPacient",
};
