import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import "./App.css";

import { BrowserRouter } from "react-router-dom";

import Routers from "../src/router";
import { AuthProvider } from "shared/providers/auth";

import { Toaster } from "react-hot-toast";

const theme = createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: "#007DED",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
          backgroundColor: "white",
          "& .MuiInputBase-root": {
            borderRadius: "24px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          backgroundColor: "white",
          border: "1px solid #EEEFF1",
          color: "#007DED",
          fontWeight: "bold",
        },
        root: {
          backgroundColor: "#007DED",
          color: "#FFF",
          border: "1px solid transparent",
          padding: "10px 30px",

          "&:hover": {
            backgroundColor: "#007DED",
            color: "#FFF",
          },
          "&.Mui-disabled": {
            backgroundColor: "#E0E0E0",
            color: "#9E9E9E",
            border: "1px solid #BDBDBD",
          },
          "&.disabledBtn": {
            backgroundColor: "white",
            color: "#ABB0BA",
            border: "1px solid #EEEFF1",
            fontWeight: "700",
          },
          "&.activeBtn": {
            backgroundColor: "white",
            color: "#007DED",
            border: "1px solid #007DED",
            fontWeight: "700",
          },

          "&.negative": {
            backgroundColor: "red",
            color: "white",
            border: "1px solid transparent",
            fontWeight: "700",
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthXl: {
          maxWidth: "1048px !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "0px !important",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <Routers />
          <Toaster />
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
