import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { HeadConsult } from "./Head";

//import { ClinicalRecord } from "./Steps/ClinicalRecord";
import { AvaliationScreen } from "./Steps/AvaliationScreen";
import { useAvaliation } from "shared/providers/Avaliation";

interface IForm {
  diagnosis: string;
  surgeryNotAlign: boolean;
  treatmentSurgery: boolean;
  complementTreatment: string[];
  bracesType: string[];
}

const ConsultSchema = yup.object().shape({
  diagnosis: yup.string().required(),
  surgeryNotAlign: yup.boolean().required(),
  treatmentSurgery: yup.boolean().required(),
  complementTreatment: yup.array().required(),
  bracesType: yup.array().required(),
});

const AvaliationStep: React.FC = () => {
  const { step } = useAvaliation();

  const methods = useForm<IForm>({
    resolver: yupResolver(ConsultSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const { handleSubmit } = methods;

  const onSubmit = async (values: IForm) => {
    console.log(values);
  };

  const stepTitle = [
    step === 1 && "1 de 2 - Procedimentos Realizados",
    step === 2 && "2 de 2 - Ficha Clínica",
  ];

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <HeadConsult title={stepTitle}>
            <AvaliationScreen />
          </HeadConsult>
        </form>
      </FormProvider>
    </>
  );
};

export { AvaliationStep };
