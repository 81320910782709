import React from "react";

import { Box, CircularProgress, Container } from "@mui/material";
import { FilterList } from "components/FilterList";
import { HeaderApp } from "components/Header";

import * as Styled from "./styles";
import { CardList } from "components/CardList";
import { useAuth } from "shared/providers/auth";

const HomePage: React.FC = () => {
  const { scheduleData, freeTime, loadingSchedule } = useAuth();

  /*React.useEffect(() => {
    const auth = localStorage.getItem("orthodontic:authentication:auth");

    if (!auth) {
      window.location.href = "/login";
    }
  }, []);*/

  return (
    <>
      <HeaderApp />
      <Container maxWidth="xl" sx={{ height: "100%" }}>
        <FilterList />
        <Styled.ScrollList>
          {loadingSchedule ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            scheduleData
              .filter(
                (item) => !freeTime || (item.data && item.data.length > 0)
              ) // Filtra os itens conforme a lógica do freeTime
              .map((item, index) => <CardList data={item} key={index} />)
          )}
        </Styled.ScrollList>
      </Container>
    </>
  );
};

export { HomePage };
