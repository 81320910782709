import React from "react";

import { AvaliationStep } from "./components/stepper";
import { Container } from "@mui/material";
import { AvaliationProvider } from "shared/providers/Avaliation";

const Avaliation: React.FC = () => {
  return (
    <AvaliationProvider>
      <Container maxWidth="xl">
        <AvaliationStep />
      </Container>
    </AvaliationProvider>
  );
};

export { Avaliation };
