import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { HeadConsult } from "./Head";
//import { PlanningMovable } from "./Steps/Planning/movable";
import { Schedule } from "./Steps/Schedule";
import { PlanningFixed } from "./Steps/Planning/fixed";
import { ProcedurePerformedFixed } from "./Steps/ProceduresPerformed/fixed";
import { ClinicalRecord } from "./Steps/ClinicalRecord";
import { useMaintenance } from "shared/providers/Maintenance";
import { ProcedurePerformedMovable } from "./Steps/ProceduresPerformed/movable";
import { PlanningMovable } from "./Steps/Planning/movable";
import QuestionModal from "components/Modal";
import { useAuth } from "shared/providers/auth";

interface IForm {
  diagnosis: string;
  surgeryNotAlign: boolean;
  treatmentSurgery: boolean;
  complementTreatment: string[];
  bracesType: string[];
}

const ConsultSchema = yup.object().shape({
  diagnosis: yup.string().required(),
  surgeryNotAlign: yup.boolean().required(),
  treatmentSurgery: yup.boolean().required(),
  complementTreatment: yup.array().required(),
  bracesType: yup.array().required(),
});

const MaintenanceStepper: React.FC = () => {
  const { step, patientObj } = useMaintenance();
  const { modalOpen, alerts, setModalOpen } = useAuth();

  const methods = useForm<IForm>({
    resolver: yupResolver(ConsultSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const { handleSubmit } = methods;

  const onSubmit = async (values: IForm) => {
    console.log(values);
  };

  const stepTitle = [
    {
      title: "[start] de [end] - Procedimentos Realizados",
      max: 4,
      step: 1,
      firstPass: 1,
    },
    {
      title: "[start] de [end] - Planejamento da Próxima Consulta",
      max: 4,
      step: 2,
      firstPass: 2,
    },
    { title: "[start] de [end] - Agendamento", max: 4, step: 3, firstPass: 3 },
    {
      title: "[start] de [end] - Ficha Clínica",
      max: 4,
      step: 4,
      firstPass: 4,
    },
  ];

  const handleCloseModal = (
    responses: { id: string; description: string }[]
  ) => {
    //console.log(responses); // Aqui você pode salvar ou enviar as respostas conforme necessário
    setModalOpen(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <HeadConsult title={stepTitle}>
            {modalOpen && (
              <QuestionModal
                questions={alerts.map((alert, index) => ({
                  id: `PUQ${(index + 1).toString().padStart(3, "0")}`, // Formatação do ID
                  message: alert.message,
                }))}
                onClose={handleCloseModal}
              />
            )}

            {step === 1 ? (
              patientObj.type === 2 ? (
                <ProcedurePerformedMovable />
              ) : (
                <ProcedurePerformedFixed />
              )
            ) : (
              ""
            )}
            {step === 2 ? (
              patientObj.type === 2 ? (
                <PlanningMovable />
              ) : (
                <PlanningFixed />
              )
            ) : (
              ""
            )}
            {step === 3 && <Schedule />}
            {step === 4 && <ClinicalRecord />}
          </HeadConsult>
        </form>
      </FormProvider>
    </>
  );
};

export { MaintenanceStepper };
