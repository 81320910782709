import { Container } from "@mui/material";
import { ReturnButton } from "components/ReturnButton";
import React from "react";

const RecoveryPage: React.FC = () => {
  return (
    <Container maxWidth="xl">
      <ReturnButton
        title="Recuperar senha"
        subtitle="Informe o e-mail vinculado a sua conta para recuperar o seu acesso."
      />
    </Container>
  );
};
export { RecoveryPage };
