import React from "react";
import * as Styled from "./styles";
import {
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useAuth } from "shared/providers/auth";
import toast from "react-hot-toast";
import { useMaintenance } from "shared/providers/Maintenance";

const PlanningMovable: React.FC = () => {
  const {
    setStep,
    othersProcedures,
    setOthersProcedures,
    othersReferrals,
    setOthersReferrals,
    valueOthers,
    setValueOthers,
    setValueEnc,
    performMolding,
    braceMovel,
    setBraceMovel,
    relizeMoldage,
    setRealizeMoldage,
    valueEnc,
  } = useMaintenance();

  const { proceduresData, referralsData } = useAuth();

  const next = () => {
    if (valueOthers.length < 1) {
      toast.error("Selecione pelo menos um procedimento.");
      return false;
    }

    setStep(3);
  };

  return (
    <Styled.Box>
      {performMolding === "YES" && (
        <Styled.BoxIndication>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={braceMovel}
                  onClick={() => setBraceMovel(!braceMovel)}
                />
              }
              label="Instalação do Aparelho Móvel"
            />
          </FormGroup>
        </Styled.BoxIndication>
      )}

      {performMolding === "NO" && (
        <Styled.BoxIndication>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={relizeMoldage}
                  onClick={() => setRealizeMoldage(!relizeMoldage)}
                />
              }
              label="Realizar a moldagem"
            />
          </FormGroup>
        </Styled.BoxIndication>
      )}

      <Styled.BoxIndication>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={othersProcedures}
                onClick={() => setOthersProcedures(!othersProcedures)}
              />
            }
            label="Outros procedimentos"
          />
        </FormGroup>

        {othersProcedures && (
          <>
            <Autocomplete
              options={proceduresData}
              multiple
              sx={{ margin: "10px 0" }}
              onChange={(event, newValue) => {
                setValueOthers(newValue);
              }}
              value={valueOthers}
              renderInput={(params) => (
                <TextField {...params} label="Outros procedimentos" />
              )}
            />
          </>
        )}
      </Styled.BoxIndication>

      <Styled.BoxIndication>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={othersReferrals}
                onClick={() => setOthersReferrals(!othersReferrals)}
              />
            }
            label="Encaminhamentos"
          />
        </FormGroup>

        {othersReferrals && (
          <>
            <Autocomplete
              options={referralsData}
              multiple
              sx={{ margin: "10px 0" }}
              onChange={(event, newValue) => {
                setValueEnc(newValue);
              }}
              value={valueEnc}
              renderInput={(params) => (
                <TextField {...params} label="Encaminhamentos" />
              )}
            />
          </>
        )}
      </Styled.BoxIndication>

      <Styled.BoxIndication>
        <TextField label="Observações gerais" fullWidth margin="normal" />
      </Styled.BoxIndication>

      <Styled.BtnFixed>
        <Button fullWidth onClick={() => setStep(1)} variant="outlined">
          Voltar
        </Button>
        <Button fullWidth onClick={next}>
          Continuar
        </Button>
      </Styled.BtnFixed>
    </Styled.Box>
  );
};

export { PlanningMovable };
