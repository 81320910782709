import API from "shared/services/Api";
import {
  IClinicalRecord,
  IClinicalRecordResponse,
} from "shared/types/ClinicalRecord";

export const getClinicalRecords = async (
  data: IClinicalRecord
): Promise<IClinicalRecordResponse> =>
  API.get(`oc/clinicalrecord?id=${data.userId}&idPac=${data.idPac}`);
