import React from "react";

import { Routes, Route } from "react-router-dom";

import { ROUTER } from "shared/constants/router";

import { Onboarding } from "components/Onboarding";
import { LoginPage } from "components/auth/login";
import { RecoveryPage } from "components/auth/recovery";
import { HomePage } from "components/Home";
import { AboutPage } from "components/About";
import { Exit } from "components/Exit";
import { FirstConsult } from "components/FirstConsult";

import { Documentation } from "components/Documentation";
import { Avaliation } from "components/AvaliationStep";

import { Maintenance } from "components/Maintenance";
import { ClinicalRecordUser } from "components/ClinicalRecord";
import { OdontogramaUser } from "components/OdontogramaUser";

const Routers: React.FC = () => {
  return (
    <Routes>
      {/* Deslogged Areas*/}
      <Route path={ROUTER.ONBOARDING} element={<Onboarding />} />
      <Route path={ROUTER.LOGIN} element={<LoginPage />} />
      <Route path={ROUTER.RECOVERY} element={<RecoveryPage />} />
      <Route path={ROUTER.HOME} element={<HomePage />} />
      <Route path={ROUTER.ABOUT} element={<AboutPage />} />
      <Route path={ROUTER.EXIT} element={<Exit />} />

      {/* Logged Areas */}
      <Route path={ROUTER.FIRST_CONSULT} element={<FirstConsult />} />

      <Route path={ROUTER.DOCUMENTATION} element={<Documentation />} />

      <Route
        path={ROUTER.CLINICAL_RECORD_USER}
        element={<ClinicalRecordUser />}
      />

      <Route path={ROUTER.AVALIATION} element={<Avaliation />} />

      <Route path={ROUTER.MAINTENANCE} element={<Maintenance />} />

      <Route path={ROUTER.ODONTOGRAMA_USER} element={<OdontogramaUser />} />
    </Routes>
  );
};

export default Routers;
