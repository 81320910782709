import React from "react";

import * as Styled from "./styles";

import { ReactComponent as MenuIcon } from "shared/assets/svg/menu.svg";
import { Avatar, Menu, MenuItem, Typography, Box, Modal } from "@mui/material";

import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { menuPatient } from "shared/mock";
import { DEFAULT_AVATAR } from "shared/config/properties";
import { ROUTER } from "shared/constants/router";
import { CloseConsult } from "components/CloseConsult";
import { Documentation } from "components/Documentation";
import { ClinicalRecordUser } from "components/ClinicalRecord";
import { useMaintenance } from "shared/providers/Maintenance";
import { TreatmentPlan } from "components/TreatmentPlan";

interface IProps {
  title: { title: string; max: number; step: number; firstPass: number }[];
  children?: any;
}

const HeadConsult: React.FC<IProps> = ({ title, children }) => {
  const {
    patientObj,
    step,
    showOtherProcedureScreen,
    proceduresLastAttendance,
    showQuestionsReturn,
  } = useMaintenance();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openClose, setOpenClose] = React.useState<boolean>(false);

  const [openCloseDocumentation, setOpenCloseDocumentation] =
    React.useState<boolean>(false);

  const [openCloseClinicalRecord, setOpenCloseClinicalRecord] =
    React.useState<boolean>(false);

  const [openCloseTreatment, setOpenCloseTreatment] =
    React.useState<boolean>(false);

  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    const row = title.find((item) => item.step === step);

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column-reverse",
          gap: "10px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box>
          <Typography
            variant="body2"
            color="#007DED"
            sx={{ fontWeight: "500", fontSize: "18px" }}
          >
            {step === 1 && showOtherProcedureScreen
              ? `${String(
                  row?.firstPass &&
                    row.firstPass +
                      (showOtherProcedureScreen ||
                      proceduresLastAttendance.length > 0
                        ? 1
                        : 0)
                )} de ${String(
                  row?.max &&
                    row.max +
                      (showOtherProcedureScreen ||
                      proceduresLastAttendance.length > 0
                        ? 1
                        : 0)
                )} - Outros Procedimentos`
              : step === 1 &&
                proceduresLastAttendance.length > 0 &&
                showQuestionsReturn
              ? `1 de ${String(
                  row?.max &&
                    row.max +
                      (showOtherProcedureScreen ||
                      proceduresLastAttendance.length > 0
                        ? 1
                        : 0)
                )} - Outros Procedimentos - Atendimento anterior`
              : row?.title
                  .replace("[end]", String(row?.max))
                  .replace("[start]", String(row?.firstPass))}
          </Typography>
        </Box>
      </Box>
    );
  }

  function LinearWithValueLabel() {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgressWithLabel
          value={
            step === 1
              ? 25
              : step === 2
              ? 50
              : step === 3
              ? 75
              : step === 4
              ? 100
              : 0
          }
        />
      </Box>
    );
  }

  return (
    <>
      <Styled.Box>
        <Styled.FakeBox></Styled.FakeBox>
        <Styled.Info>
          <Avatar
            src={patientObj.pac_avatar ? patientObj.pac_avatar : DEFAULT_AVATAR}
            sx={{ width: "55px", height: "55px" }}
            alt={patientObj.pac_name}
          />
          <Typography sx={{ color: "#919296", fontWeight: "500" }}>
            ID {patientObj.pac_id_fant}
          </Typography>
          <Typography
            sx={{ color: "#343536", fontWeight: "bold", fontSize: "20px" }}
          >
            {patientObj.pac_name}
          </Typography>
          <LinearWithValueLabel />
        </Styled.Info>
        <Styled.Menu>
          <button
            onClick={handleClick}
            style={{
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <MenuIcon />
          </button>
        </Styled.Menu>
      </Styled.Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuPatient.map((item, index) => (
          <>
            {item.href === "/TRATAMENTO" ? (
              <MenuItem key={index}>
                <Styled.NoLink onClick={() => setOpenCloseTreatment(true)}>
                  {<item.icon />} {item.text}
                </Styled.NoLink>
              </MenuItem>
            ) : item.href === "/DOC" ? (
              <MenuItem key={index}>
                <Styled.NoLink onClick={() => setOpenCloseDocumentation(true)}>
                  {<item.icon />} {item.text}
                </Styled.NoLink>
              </MenuItem>
            ) : item.href === "/FICHA" ? (
              <MenuItem key={index}>
                <Styled.NoLink onClick={() => setOpenCloseClinicalRecord(true)}>
                  {<item.icon />} {item.text}
                </Styled.NoLink>
              </MenuItem>
            ) : item.href !== ROUTER.CLOSE_CONSULT ? (
              <MenuItem key={index}>
                <Styled.Link href={item.href}>
                  {<item.icon />} {item.text}
                </Styled.Link>
              </MenuItem>
            ) : (
              <MenuItem key={index}>
                <Styled.NoLink onClick={() => setOpenClose(true)}>
                  {<item.icon />} {item.text}
                </Styled.NoLink>
              </MenuItem>
            )}
          </>
        ))}

        <Modal open={openClose} onClose={() => setOpenClose(false)}>
          <Box sx={{ backgroundColor: "white", height: "100%", width: "100%" }}>
            <CloseConsult
              close={() => setOpenClose(false)}
              idSchedule={patientObj.id}
            />
          </Box>
        </Modal>

        <Modal
          open={openCloseDocumentation}
          onClose={() => setOpenCloseDocumentation(false)}
        >
          <Box sx={{ backgroundColor: "white", height: "100%", width: "100%" }}>
            <Documentation
              onClick={() => setOpenCloseDocumentation(false)}
              idPacientParam={patientObj.pac_id}
            />
          </Box>
        </Modal>

        <Modal
          open={openCloseClinicalRecord}
          onClose={() => setOpenCloseClinicalRecord(false)}
        >
          <Box sx={{ backgroundColor: "white", height: "100%", width: "100%" }}>
            <ClinicalRecordUser
              onClick={() => setOpenCloseClinicalRecord(false)}
              idPacientParam={patientObj.pac_id}
            />
          </Box>
        </Modal>

        <Modal
          open={openCloseTreatment}
          onClose={() => setOpenCloseTreatment(false)}
        >
          <Box sx={{ backgroundColor: "white", height: "100%", width: "100%" }}>
            <TreatmentPlan
              onClick={() => setOpenCloseTreatment(false)}
              idPacientParam={patientObj.pac_id}
            />
          </Box>
        </Modal>
      </Menu>

      {children}
    </>
  );
};

export { HeadConsult };
