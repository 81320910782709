import React from "react";

import * as Styled from "./styles";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IAuthLogin } from "shared/types/Auth";

import { ReactComponent as EyeOff } from "shared/assets/svg/eye-off.svg";
import { ReactComponent as Eye } from "shared/assets/svg/eye.svg";
import { ReactComponent as Email } from "shared/assets/svg/emailIcon.svg";
import { ReactComponent as Lock } from "shared/assets/svg/lock.svg";
import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useAuth } from "shared/providers/auth";
//import { ROUTER } from "shared/constants/router";

const LoginForm: React.FC = () => {
  const { signIn, loading } = useAuth();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const LoginSchema = yup.object().shape({
    login: yup.string().required("Campo obrigatório."),
    password: yup.string().required("Campo obrigatório."),
  });

  const { handleSubmit, control } = useForm<IAuthLogin>({
    resolver: yupResolver(LoginSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (values: IAuthLogin) => {
    signIn(values);
  };

  return (
    <Styled.Content>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-5">
          <Controller
            control={control}
            name="login"
            render={({ field: { ...field }, fieldState: { error } }) => (
              <>
                <TextField
                  label="Login"
                  placeholder="Login"
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                />

                {error && error.message && (
                  <p className="error-message">{error.message}</p>
                )}
              </>
            )}
          />
        </div>

        <div className="mb-5">
          <Controller
            control={control}
            name="password"
            render={({ field: { ...field }, fieldState: { error } }) => (
              <>
                <TextField
                  label="Senha"
                  type={showPassword ? "text" : "password"}
                  placeholder="************"
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={toggleShowPassword}
                      >
                        {showPassword ? <Eye /> : <EyeOff />}
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                />

                {error && error.message && (
                  <p className="error-message">{error.message}</p>
                )}
              </>
            )}
          />
        </div>

        <Button type="submit" fullWidth>
          {loading ? <CircularProgress color="inherit" /> : "ENTRAR"}
        </Button>

        {/* <Styled.More>
          <a href={ROUTER.RECOVERY}>Esqueci minha senha</a>
          </Styled.More> */}
      </form>
    </Styled.Content>
  );
};

export { LoginForm };
