import React from "react";

import { Schedule } from "shared/types/Schedule";
import { IOdont } from "shared/types/SendSchedule";

interface IProps {
  children: any;
}

export interface IAvaliationContext {
  step: number;
  setStep: (value: number) => void;
  patientObj: Schedule;
  firstQuestion: string;
  setFirstQuestion: (value: string) => void;
  firstReason: string;
  setFirstReason: (value: string) => void;
  secondQuestion: string;
  setSecondQuestion: (value: string) => void;
  care: boolean;
  setCare: (value: boolean) => void;
  careReason: string;
  setCareReason: (value: string) => void;
  careReasonError: string;
  setCareReasonError: (value: string) => void;
  periodont: boolean;
  setPeriodont: (value: boolean) => void;
  periodontReason: string;
  setPeriodontReason: (value: string) => void;
  periodontReasonError: string;
  setPeriodontReasonError: (value: string) => void;
  cirurgy: boolean;
  setCirurgy: (value: boolean) => void;
  cirurgyReason: string;
  setCirurgyReason: (value: string) => void;
  cirurgyReasonError: string;
  setCirurgyReasonError: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  descriptionError: string;
  setDescriptionError: (value: string) => void;

  odontogramaCarie: IOdont[];
  setOdontogramaCarie: (value: (prev: IOdont[]) => IOdont[]) => void;

  odontogramaProblem: IOdont[];
  setOdontogramaProblem: (value: (prev: IOdont[]) => IOdont[]) => void;

  odontogramaCirurgy: IOdont[];
  setOdontogramaCirurgy: (value: (prev: IOdont[]) => IOdont[]) => void;

  reason: string;
  setReason: (value: string) => void;
  reasonError: string;
  setReasonError: (value: string) => void;
}

const AvaliationContext = React.createContext({} as IAvaliationContext);

const AvaliationProvider: React.FC<IProps> = ({ children }) => {
  const [step, setStep] = React.useState<number>(1);
  const [firstQuestion, setFirstQuestion] = React.useState<string>("");
  const [firstReason, setFirstReason] = React.useState<string>("");
  const [secondQuestion, setSecondQuestion] = React.useState<string>("");

  const [care, setCare] = React.useState<boolean>(false);
  const [careReason, setCareReason] = React.useState<string>("");
  const [careReasonError, setCareReasonError] = React.useState<string>("");

  const [periodont, setPeriodont] = React.useState<boolean>(false);
  const [periodontReason, setPeriodontReason] = React.useState<string>("");
  const [periodontReasonError, setPeriodontReasonError] =
    React.useState<string>("");

  const [cirurgy, setCirurgy] = React.useState<boolean>(false);
  const [cirurgyReason, setCirurgyReason] = React.useState<string>("");
  const [cirurgyReasonError, setCirurgyReasonError] =
    React.useState<string>("");

  const [description, setDescription] = React.useState<string>("");
  const [descriptionError, setDescriptionError] = React.useState<string>("");

  const [odontogramaCarie, setOdontogramaCarie] = React.useState<IOdont[]>([]);

  const [odontogramaProblem, setOdontogramaProblem] = React.useState<IOdont[]>(
    []
  );

  const [odontogramaCirurgy, setOdontogramaCirurgy] = React.useState<IOdont[]>(
    []
  );

  const [reason, setReason] = React.useState<string>("");
  const [reasonError, setReasonError] = React.useState<string>("");

  const patientActually = localStorage.getItem("personAvaliation");

  const patientObj = JSON.parse(patientActually as string);

  const value = React.useMemo(
    () => ({
      step,
      setStep,
      patientObj,
      firstQuestion,
      setFirstQuestion,
      firstReason,
      setFirstReason,
      secondQuestion,
      setSecondQuestion,
      care,
      setCare,
      careReason,
      setCareReason,
      careReasonError,
      setCareReasonError,
      periodont,
      setPeriodont,
      periodontReason,
      setPeriodontReason,
      periodontReasonError,
      setPeriodontReasonError,
      cirurgy,
      setCirurgy,
      cirurgyReason,
      setCirurgyReason,
      cirurgyReasonError,
      setCirurgyReasonError,
      description,
      setDescription,
      descriptionError,
      setDescriptionError,
      odontogramaCarie,
      setOdontogramaCarie,
      odontogramaProblem,
      setOdontogramaProblem,
      odontogramaCirurgy,
      setOdontogramaCirurgy,
      reason,
      setReason,
      reasonError,
      setReasonError,
    }),
    [
      step,
      setStep,
      patientObj,
      firstQuestion,
      setFirstQuestion,
      firstReason,
      setFirstReason,
      secondQuestion,
      setSecondQuestion,
      care,
      setCare,
      careReason,
      setCareReason,
      careReasonError,
      setCareReasonError,
      periodont,
      setPeriodont,
      periodontReason,
      setPeriodontReason,
      periodontReasonError,
      setPeriodontReasonError,
      cirurgy,
      setCirurgy,
      cirurgyReason,
      setCirurgyReason,
      cirurgyReasonError,
      setCirurgyReasonError,
      description,
      setDescription,
      descriptionError,
      setDescriptionError,
      odontogramaCarie,
      setOdontogramaCarie,
      odontogramaProblem,
      setOdontogramaProblem,
      odontogramaCirurgy,
      setOdontogramaCirurgy,
      reason,
      setReason,
      reasonError,
      setReasonError,
    ]
  );

  return (
    <AvaliationContext.Provider value={value}>
      {children}
    </AvaliationContext.Provider>
  );
};

const useAvaliation = (): IAvaliationContext =>
  React.useContext(AvaliationContext);

export { AvaliationContext, AvaliationProvider, useAvaliation };
