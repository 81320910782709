import styled from "styled-components";

type IProps = {
  filter?: boolean;
};

export const Container = styled.div<IProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;

  span {
    padding: 15px;
    background-color: #ffffff;
    border-radius: 50%;
    height: 15px;
    width: 17px;
  }

  p {
    text-align: center;
    margin-right: 50px;
    font-size: 20px;
    font-weight: bold;
    color: #343536;

    p {
      margin-right: 0px !important;
      font-size: 14px;
      color: #556370;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`;

export const MenuButton = styled.div`
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    border: 1px solid #e3e7ec;
    padding: 16px;
    border-radius: 50%;
  }
`;
