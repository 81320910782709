import React from "react";

import * as S from "./styles";

import { ReactComponent as ArrowIcon } from "shared/assets/svg/arrow-left.svg";

interface IProps {
  title?: string;
  subtitle?: string;
  onClick?: (value: any) => void;
  filter?: boolean;
}

const ReturnButton: React.FC<IProps> = ({
  title,
  subtitle,
  onClick,
  filter,
}) => {
  return (
    <S.Container filter={filter}>
      <span onClick={onClick ? onClick : () => window.history.back()}>
        <ArrowIcon />
      </span>

      <p>
        {title}
        <p>{subtitle}</p>
      </p>

      <div></div>
    </S.Container>
  );
};

export { ReturnButton };
