import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  border-radius: 5px;
  background-color: #fff;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  min-height: 81px;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 50%;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 50%;
  justify-content: end;
  padding: 10px 0;
`;

export const HourBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 23%;
`;

export const TimeBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const HourStyle = styled.p`
  color: #007ded;
  font-weight: 700;
  font-size: 18px;
`;

export const BoxSituation = styled.div<{ situation: string }>`
  background-color: ${(item) =>
    item.situation === "C"
      ? "#44de00"
      : item.situation === "T"
      ? "#b8fab8"
      : item.situation === "A"
      ? "#c7d5e1"
      : item.situation === "E"
      ? "#a0c6fa"
      : item.situation === "K"
      ? "#92c3b3"
      : item.situation === "M"
      ? "#ffde00"
      : item.situation === "N"
      ? "#ff7878"
      : item.situation === "Y"
      ? "#f4ce93"
      : item.situation === "" && "#E88C30"};

  padding: 5px;
  text-transform: uppercase;
  color: white;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  border-radius: 5px;
`;

export const BoxInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 65%;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
`;

export const Picture = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const Id = styled.p`
  color: #919296;
`;

export const Name = styled.p`
  color: #343536;
  font-weight: 700;
`;

export const TypeConsult = styled.p`
  font-weight: bold;
  background-color: #eeeff1;
  color: #556370;
  text-align: center;
  padding: 2px 4px;
  width: 95%;
`;

export const More = styled.div`
  background-color: transparent;
  border: none;
`;

export const BoxBtn = styled.div`
  display: flex;
  gap: 10px;
  width: 27%;
  padding: 10px 0;
  justify-content: center;
`;

export const BoxBtnFake = styled.div`
  display: flex;
  gap: 10px;
  width: 27%;
  padding: 10px 0;
  justify-content: center;

  button {
    background-color: transparent !important;
    color: transparent !important;
  }
`;

export const BoxModal = styled.div`
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  gap: 20px;
  justify-content: center;
  align-items: center;

  p {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const BoxButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  button {
    width: 48%;
  }
`;
