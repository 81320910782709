import React from "react";

import { Container } from "@mui/material";

import { ReactComponent as Logo } from "shared/assets/svg/logo_horizontal.svg";

import * as Styled from "./styles";
import { LoginForm } from "./form";

const LoginPage: React.FC = () => {
  return (
    <Container maxWidth="xl">
      <Styled.Content>
        <Logo style={{ width: "70%" }} />
        <LoginForm />
      </Styled.Content>
    </Container>
  );
};

export { LoginPage };
