/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import * as Styled from "./styles";

import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { ReturnButton } from "components/ReturnButton";
import { getDocuments } from "requests/Documentation";
import { useAuth } from "shared/providers/auth";
import { useParams } from "react-router-dom";
import { FaDownload } from "react-icons/fa";

interface ResponseData {
  [key: string]: string;
}

const Documentation: React.FC<{
  idPacientParam?: number;
  onClick?: () => void;
}> = ({ idPacientParam, onClick }) => {
  const { user } = useAuth();
  const { idPacient } = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [objDoc, setObjDoc] = React.useState<string[]>([]);

  const transformDataToArray = (data: ResponseData): string[] => {
    return Object.keys(data)
      .filter((key) => !isNaN(Number(key)))
      .map((key) => data[key]);
  };

  const documentsAll = async () => {
    setLoading(true);

    try {
      setLoading(false);
      const { status, data } = await getDocuments({
        userId: user.data.id,
        idPac: Number(
          idPacientParam && idPacientParam > 0 ? idPacientParam : idPacient
        ),
      });

      if ([200, 201].includes(status)) {
        const transformedData: string[] = transformDataToArray(data.data);

        setObjDoc(transformedData);
      } else {
        throw new Error("Erro ao carregar as fotos.");
      }
    } catch (e: any) {
      setLoading(false);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    documentsAll();
  }, []);

  return (
    <Container maxWidth="xl">
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <ReturnButton title="Documentação" onClick={onClick} />

          <Styled.ContainerPictures>
            {objDoc.length > 0 ? (
              objDoc.map((item, index) => {
                const isPDF = item.endsWith(".pdf");
                return isPDF ? (
                  <Box key={index} sx={{ margin: "10px" }}>
                    <a href={item} download>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <FaDownload /> Baixar PDF {index + 1}
                      </Typography>
                    </a>
                  </Box>
                ) : (
                  <img
                    key={index}
                    src={item}
                    alt="Documentation"
                    style={{ margin: "10px" }}
                  />
                );
              })
            ) : (
              <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                  Não há documentação para este paciente.
                </Typography>
              </Box>
            )}
          </Styled.ContainerPictures>
        </>
      )}
    </Container>
  );
};

export { Documentation };
