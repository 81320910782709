import axios from "axios";

import { API_URL } from "shared/config/properties";
import { AUTH_TOKEN } from "shared/constants/auth";
import { ROUTER } from "shared/constants/router";

const axiosInstance = axios.create({
  baseURL: API_URL,
});

const excessionRouter = [ROUTER.LOGIN, ROUTER.ONBOARDING];

axiosInstance.interceptors.request.use((config: any) => {
  let token: any = localStorage.getItem(AUTH_TOKEN);

  if (token && config) {
    token = JSON.parse(token);
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  return config;
});

axiosInstance.interceptors.response.use(undefined, (error) => {
  if (error.response && error.response.status === 401) {
    if (!excessionRouter.includes(window.location.pathname)) {
      window.location.href = ROUTER.LOGIN;
      localStorage.clear();
    }
  }
  return error.response;
});

export default axiosInstance;
