import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "shared/providers/auth";

interface Question {
  id: string;
  message: string;
}

interface QuestionModalProps {
  questions: Question[];
  onClose: (
    responses: { id: string; description: string; message: string }[]
  ) => void; // Atualização do tipo de resposta
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const QuestionModal: React.FC<QuestionModalProps> = ({
  questions,
  onClose,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const { responses, setResponses } = useAuth();
  const [loading, setLoading] = useState(false); // Estado para controlar o loading

  const handleResponse = (response: string) => {
    const currentQuestion = questions[currentQuestionIndex];
    setResponses([
      ...responses,
      {
        id: currentQuestion.id,
        description: response,
        message: currentQuestion.message,
      },
    ]);

    if (currentQuestionIndex < questions.length - 1) {
      setLoading(true); // Ativa o loading
      setTimeout(() => {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setLoading(false); // Desativa o loading após o tempo
      }, 1000); // Atraso de 2 segundos
    } else {
      onClose(responses);
    }
  };

  return (
    <Modal open={true} onClose={() => onClose(responses)}>
      <Box sx={style}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography variant="h6" component="h2">
              {questions[currentQuestionIndex].message}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleResponse("Sim")}
                fullWidth
              >
                Sim
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleResponse("Não")}
                fullWidth
                className="negative"
              >
                Não
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default QuestionModal;
