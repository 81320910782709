import React from "react";

import { MaintenanceStepper } from "./components/stepper";
import { Container } from "@mui/material";
import { MaintenanceProvider } from "shared/providers/Maintenance";

const Maintenance: React.FC = () => {
  return (
    <MaintenanceProvider>
      <Container maxWidth="xl">
        <MaintenanceStepper />
      </Container>
    </MaintenanceProvider>
  );
};

export { Maintenance };
