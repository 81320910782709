import styled from "styled-components";

export const BgImage = styled.div`
  display: flex;
  justify-content: start;
  background: url("/doctor.jpg") no-repeat;
  height: 409px;
  padding: 30px 20px;

  span {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    height: 15px;
    width: 17px;
  }

  @media (min-width: 1030px) {
    background-size: cover;
    height: 200px;
  }
`;

export const Box = styled.div`
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  height: 47vh;

  @media (min-width: 1030px) {
    overflow: hidden;
    height: auto;
  }
`;

export const Title = styled.p`
  font-size: 32px;
  color: #343536;
  font-weight: 700;
  text-align: center;

  @media (min-width: 1030px) {
    text-align: left;
  }
`;

export const Phrase = styled.p`
  font-size: 15px;
  font-weight: 500;
  margin: 10px 0;
  color: #343536;

  @media (min-width: 1030px) {
    text-align: left;
  }
`;
