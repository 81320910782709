import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 25px;
  padding: 0 1px;

  .dates {
    margin-top: 5px;
  }
`;

export const BoxDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #343536;
  }
`;

export const BoxIndication = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #eeeff1;
  padding: 5px 15px;
  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #343536;
  }
`;

export const BoxButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  gap: 20px;
`;

export const BtnFixed = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  padding: 15px 0;
`;

export const BtnFixedAnam = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  padding: 15px 0;
`;
