import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import * as Styled from "./styles";
import { useFirstConsult } from "shared/providers/FirstConsult";
import { Question, questions } from "shared/data/question";
import { IAnamnese /*IAnamneseData*/ } from "shared/types/Anamnese";
//import { useAuth } from "shared/providers/auth";
//import { postAnamnese } from "requests/Anamnese";
//import toast from "react-hot-toast";

const options: IAnamnese[] = [
  { label: "Asma", identify: "OCQ1" },
  { label: "Anemia", identify: "OCQ2" },
  { label: "Febre reumática", identify: "OCQ3" },
  { label: "Epilepsia", identify: "OCQ4" },
  { label: "Distúrbios auditivos", identify: "OCQ5" },
  { label: "Herpes", identify: "OCQ6" },
  { label: "Hepatite", identify: "OCQ7" },
  { label: "Diabete", identify: "OCQ8" },
  { label: "HIV", identify: "OCQ9" },
  { label: "Gestante", identify: "OCQ10" },
  { label: "Fumante", identify: "OCQ11" },
  { label: "Hipertensão", identify: "OCQ12" },
];

const Anamnese: React.FC = () => {
  ///const { user } = useAuth();
  const {
    setStep,
    selectedOptions,
    handleOptionChange,
    currentQuestionIndex,
    answers,
    handleAnswerChange,
    observations,
    errors,
    handleObservationChange,
    //patientObj,
  } = useFirstConsult();

  const anamneseItemQuestion = ({
    question,
    index,
    show = true,
  }: {
    question: Question;
    index: number;
    show?: boolean;
  }) => (
    <div key={question.questionText}>
      {show && (
        <Styled.BoxDescription
          style={{
            marginBottom: 4,
            display: index <= currentQuestionIndex ? "block" : "none",
          }}
        >
          <Typography variant="h5">{question.questionText}</Typography>
          <Styled.BoxButtons>
            <Button
              variant={
                answers[question.questionText] === "não"
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                handleAnswerChange(
                  question.questionText,
                  "não",
                  typeof question.answerValidate === "undefined"
                )
              }
            >
              NÃO
            </Button>
            <Button
              variant={
                answers[question.questionText] === "sim"
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                handleAnswerChange(
                  question.questionText,
                  "sim",
                  typeof question.answerValidate === "undefined"
                )
              }
            >
              SIM
            </Button>
          </Styled.BoxButtons>

          {answers[question.questionText] === "sim" &&
            question.questionText !==
              "A recuperação aconteceu como esperado?" && (
              <TextField
                label="Observação"
                value={observations[question.questionText] || ""}
                onChange={(e) =>
                  handleObservationChange(question.questionText, e.target.value)
                }
                fullWidth
                margin="normal"
                error={!!errors[question.questionText]}
                helperText={errors[question.questionText]}
              />
            )}

          {answers[question.questionText] === "não" &&
            question.questionText ===
              "A recuperação aconteceu como esperado?" && (
              <TextField
                label="Observação"
                value={observations[question.questionText] || ""}
                onChange={(e) =>
                  handleObservationChange(question.questionText, e.target.value)
                }
                fullWidth
                margin="normal"
                error={!!errors[question.questionText]}
                helperText={errors[question.questionText]}
              />
            )}

          {question.questionText === "Problemas endócrinos?" &&
            answers[question.questionText] === "sim" && (
              <Typography>
                Avaliar problemas que possam interferir no tratamento
                ortodôntico
              </Typography>
            )}
        </Styled.BoxDescription>
      )}
    </div>
  );

  const next = async () => {
    /*const answeredYes = questions.filter(
      (question) => answers[question.questionText] === "sim"
    );

    const answeredNo = questions.filter(
      (question) => answers[question.questionText] === "não"
    );

    const combinedData = [
      ...selectedOptions.map((item) => ({
        id: item.identify,
        option: 1,
      })),
      ...answeredYes.map((item) => ({
        id: item.questionIdentifier,
        option: 1, // Assuming '1' represents 'Yes' here
        observation: observations[item.questionText] || "",
      })),
      ...answeredNo.map((item) => ({
        id: item.questionIdentifier,
        option: 0,
      })),
    ];

    const payload: IAnamneseData = {
      idPac: patientObj.pac_id,
      id: user.data.id,
      data: combinedData,
    };

    console.log(payload);*/

    //const { status } = await postAnamnese(payload);

    //if ([200, 201].includes(status)) {
    setStep(3);
    //} else {
    //  toast.error("Erro ao salvar Anamnese");
    //}
  };

  const handleVerifyQuestions = React.useCallback(
    (question: Question): boolean => {
      let resp = true;

      if (question.answerValidate?.length > 0) {
        for (const rule of question.answerValidate) {
          //Tratamento de any
          if (typeof answers[rule[0]] !== "undefined" && rule[1] === "any") {
            resp = true;
            break;
          }

          // console.log(rule[0], answers[rule[0]], rule[1]);
          // console.log(answers[rule[0]] === rule[1]);
          resp = answers[rule[0]] === rule[1];

          if (resp) {
            // const currentIndex = questions.findIndex(
            //   (q) => q.questionText === question.questionText
            // );
            // setCurrentQuestionIndex(currentIndex + 2);
            // break;
          }

          // if (question.answerValidate[1] === "any") {
          //   return true;
          // }

          // return (
          //   answers[question.answerValidate[0]] === question.answerValidate[1]
          // );
        }
      }

      return resp;
    },
    [answers]
  );

  return (
    <Styled.Box>
      <Typography variant="h5">Anamnese</Typography>
      <Styled.AriaBox>
        {options.map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={selectedOptions.includes(item)}
                onChange={() => handleOptionChange(item)}
              />
            }
            label={item.label}
          />
        ))}
      </Styled.AriaBox>

      {questions.map((question, index) => (
        <React.Fragment key={question.questionText}>
          {anamneseItemQuestion({
            question,
            index,
            show: handleVerifyQuestions(question),
          })}
        </React.Fragment>
      ))}
      {currentQuestionIndex >= questions.length && (
        <Styled.BtnFixed>
          <Button fullWidth onClick={() => setStep(1)} variant="outlined">
            Voltar
          </Button>
          <Button fullWidth onClick={next}>
            Continuar
          </Button>
        </Styled.BtnFixed>
      )}
    </Styled.Box>
  );
};

export { Anamnese };
