import React from "react";
import * as Styled from "./styles";
import {
  odontogramaInferior,
  odontogramaSuperior,
} from "shared/data/odontograma";
import { Box, Button, Container, Typography } from "@mui/material";
import { ReturnButton } from "components/ReturnButton";
import { IOdont } from "shared/types/SendSchedule";

interface IProps {
  onClose: () => void;
  subtitle?: string;
  selected: IOdont[];
  setSelected: (value: (prev: IOdont[]) => IOdont[]) => void;
}

const Odontograma: React.FC<IProps> = ({
  onClose,
  subtitle = "",
  selected,
  setSelected,
}) => {
  const handleSelect = (id: number) => {
    setSelected((prev) => {
      const index = prev.findIndex((item) => item.tooth === id);
      if (index >= 0) {
        return prev.filter((item) => item.tooth !== id);
      } else {
        return [...prev, { tooth: id, procedure: subtitle }];
      }
    });
  };

  return (
    <Box sx={{ backgroundColor: "white", height: "100%", width: "100%" }}>
      <Container>
        <ReturnButton
          title="Odontograma"
          subtitle={subtitle}
          onClick={onClose}
        />
        <Styled.Odontograma>
          <Styled.Superiores>
            {odontogramaSuperior.map((dado) => (
              <Styled.Box
                key={dado.id}
                onClick={() => handleSelect(dado.id)}
                selected={!!selected.find((item) => item.tooth === dado.id)}
              >
                <dado.img />
                <Typography>{dado.id}</Typography>
              </Styled.Box>
            ))}
          </Styled.Superiores>
          <Styled.Inferiores>
            {odontogramaInferior.map((dado) => (
              <Styled.Box
                key={dado.id}
                onClick={() => handleSelect(dado.id)}
                selected={!!selected.find((item) => item.tooth === dado.id)}
              >
                <Typography>{dado.id}</Typography>
                <dado.img />
              </Styled.Box>
            ))}
          </Styled.Inferiores>
        </Styled.Odontograma>

        <Styled.BtnFixed>
          <Button fullWidth onClick={onClose}>
            Continuar
          </Button>
        </Styled.BtnFixed>
      </Container>
    </Box>
  );
};

export { Odontograma };
